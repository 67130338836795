@import "../../../../style/variables.scss";

.fullsize {
    &-wrapper {
        width: 20px;
        height: 20px;
        padding: 2.5px;
        border: 0.1px solid $light-grey;
        border-radius: 2.5px;
        cursor: pointer;
        transition: $transition;
        background: rgb(227, 227, 227);

        &:hover {
            background: rgb(197, 197, 197);
            box-shadow: 0 0 5px rgba(100, 100, 100, 0.3);
        }
    }

    &-body {
        position: relative;
        width: 100%;
        height: 100%;

        overflow: hidden;

        &-1,
        &-2,
        &-3,
        &-4 {
            position: absolute;
            min-width: 6px;
            transition: $transition;
        }

        &-1 {
            top: 0;
            left: 0;
            transform: rotate(135deg) translate(-80%, 40%);
            &.a {
                transform: rotate(315deg) translate(75%, -10%);
            }
        }

        &-2 {
            top: 0;
            right: 0;
            transform: rotate(-135deg) translate(75%, 40%);
            &.a {
                transform: rotate(45deg) translate(-80%, -10%);
            }
        }

        &-3 {
            bottom: 0;
            left: 0;
            transform: rotate(45deg) translate(75%, 40%);
            &.a {
                transform: rotate(225deg) translate(-80%, -10%);
            }
        }

        &-4 {
            bottom: 0;
            right: 0;
            transform: rotate(-45deg) translate(-80%, 40%);
            &.a {
                transform: rotate(135deg) translate(75%, -10%);
            }
        }
    }
}
