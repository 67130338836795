@media screen and (max-width: 950px) {
  .chess-display_webinar {
    .chess-display__man {
      height: 30px;
      width: 20px;
    }
  }
}


@media screen and (max-width: 900px) {
  .chess-display {
    display: block;
    min-height: auto;

    &__description--repeat {
      justify-content: flex-start;
    }

    &__picture {
      min-height: 200px;
      max-height: 200px;
      width: 100%;
      align-self: center;
      &_webinar {
        margin-left: 0;
      };
    }

    &__chessboard {
      display: flex;
      justify-content: center;
    }

    &__container {

      padding: 0;

      &--buttons {
        padding: 0;
      }
    }

    &__wrapper {
      flex-direction: column;
      height: auto;
    }

    &__buttons-container {
      padding: 15px;
      &--mobile {
        padding-top: 0;
        display: flex;
      }
    }

    &__toggle-buttons-container {
      display: flex;
      flex-grow: 1;
      background-color: #EEEEEE;
      padding: 2px;
      border-radius: 6px;
      margin-top: 10px;
    }

    &__toggle-button {
      flex-grow: 1;
      padding: 4px 10px;
      border-radius: 6px;
      font-weight: bold;
      font-size: 12px;
      &--active {
        background-color: white;
      }
    }

    &__description {
      max-width: none;
      margin: 0;
      padding: 15px 0 15px;
    }

    &__title,
    &__name {
      font-size: 16px;
      text-align: center;
      margin: 0;
    }

    &__step {
      font-size: 16px;
      margin-top: 15px;

      &--desktop {
        display: none;
      }
    }

    &__man {
      height: 24px;
      width: 9px;
      margin: 2px;
    }

    &__dotes {
      margin: 15px 0;
      max-height: 2px;

      &--mobile {
        display: flex;
        margin-bottom: 0;
      }

      &--desktop {
        display: none;
      }
    }

    &__hint {
      height: 40px;
      width: 40px;
      right: 15px;
      bottom: 20px;

      &--repeat {
        bottom: 0;
      }
    }

    &__step-container {
      display: none;
    }

    &__control-button {
      padding: 20px;
    }

    &__control-title {
      color: var(--text-black);
    }

    &__start-button {
      padding: 8px 25px;
      border-radius: 6px;
    }

    &__table {
      width: 100%;
      height: 300px;
      max-height: 300px;
    }

    &__control-container {
      display: flex;
      justify-content: center;
    }
  }

}