@media screen and (max-width: 650px) {
  .courses {
    &__wrapper {
      padding: 20px 5px 0 5px;
    }

    &__list {
      padding: 20px 15px;
    }

    &__item {
      margin-bottom: 25px;
      min-height: 100px;
    }

    &__title {
      font-size: 16px;
      margin: 4px 0;
    }

    &__item-image {
      flex-grow: 0;
      min-width: 150px;
      width: 155px;
      height: auto;
    }

    &__progress-container {
      max-width: 190px;
      padding: 5px 5px 5px 0;
      flex-wrap: wrap;
    }

    &__info {
      padding: 5px 25px 5px 10px;
      width: 180px;
    }

    &__name {
      font-size: 12px;
    }

    &__title {
      font-size: 14px;
    }

    &__count {
      font-size: 12px;
    }

    &__cup {
      right: 0;
      top: 4px;
      transform: translateY(-50%);
      width: 38px;
      height: 45px;
    }
  }

  .courses__navigation-list {
    .courses__navigation-item {
      justify-content: center;
      flex-grow: 1;

      .courses__navigation-link {
        padding: 15px 10px;
      }
    }
  }
}