@media screen and (max-width: 760px) {
  .search__mobile-popup {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    z-index: 100;
    padding: 40px 0 0 0;
  }

  .search__mobile-container {
    position: relative;
  }

  .search__results {
    box-shadow: none;
    overflow-y: auto;
    max-height: 90vh;
  }

}