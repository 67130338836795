.page-container {
  display: flex;
  flex-grow: 1;

  &__wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
  }
}