.lazy-wrapper {
  display: flex !important;
  justify-content: center !important;
  height: 100%;
  background: linear-gradient(90deg, rgba(109,194,187,0.15) 0%, rgba(255,255,255,1) 50%, rgba(109,194,187,0.15) 55%);
  background-size: 400% 400%;
  animation: gradient 1.5s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}