.section-grid {
  &__item {
    cursor: pointer;
    background-color: var(--main-white);
    position: relative;
    border-radius: var(--course-border-radius);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-shadow: -4px 4px 12px rgba(30, 30, 30, 0.23);
    flex-grow: 1;
    max-width: 260px;
    width: 100%;
    margin: 15px 30px 15px 0;
    -webkit-backface-visibility: hidden;
    transition: 0.3s;

    &:hover {
      transform: translateY(-6px);
      box-shadow: -8px 8px 18px rgba(30, 30, 30, 0.25);
    }
  }

  &__card-image {
    -webkit-backface-visibility: hidden;
    outline: 1px solid transparent;
    display: block;
    border: none;
    min-height: 180px;
    max-height: 180px;
    object-fit: cover;
    width: 100%;
    background-color: rgba(109, 194, 187, 0.15);
    background-position: center;
    background-size: 40px;
    background-repeat: no-repeat;
    background-image: url("../../../assets/img/logo_single.png");
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    align-items: flex-start;
    padding: 10px 15px;
  }

  &__card-name {
    font-size: 14px;
  }

  &__card-title {
    font-weight: bold;
    margin: 6px 0;
    font-size: 14px;
  }

  &__card-count {
    margin-top: 6px;
  }
}


.section-grid__item--loading {
  background: #f6f7f8;
  background: linear-gradient(to right, #fafafa 8%, #f4f4f4 38%, #fafafa 54%);
  background-size: 1000px 640px;
  animation: onLoading 1.5s infinite;

  .section-grid {
    &__card-image {
      opacity: 0.3;
      background-image: url("../../../assets/img/logo_single.png");
      background-position: center;
      background-size: 30px;
      background-color: rgba(109, 194, 187, 0.25);
    }

    &__card-title,
    &__card-name,
    &__card-count {
      height: 16px;
      width: 100px;
      background-color: rgba(109, 194, 187, 0.1);
    }

    &__card-title {
      width: 180px;
    }

    &__card-count {
      width: 140px;
    }

  }

}