@media screen and (max-width: 900px) {
  .tasks {
    background-color: rgba(0,0,0,0.1);
    border-right: 1px solid var(--course-border);
    border-left: 1px solid var(--course-border);

    &__list {
      max-height: 120vh;
      overflow-y: auto;
    }

    &__item {
      display: flex;
      align-items: center;
      padding: 10px 15px;
      border-bottom: 1px solid var(--course-border);

      &:hover {
        cursor: default;
        background-color: rgb(245,245,245);
      }

      &--active {
        background-color: white;
      }
    }

    &__image {
      width: 45px;
      height: 45px;
      margin-right: 18px;
    }

    &__title {
      font-size: 14px;
      margin-bottom: 4px;
    }

    &__count {
      font-size: 12px;
    }
  }
}