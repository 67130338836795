@import "../../../../style/variables.scss";
@import "../../../../style/mixins.scss";

.single-item {
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    background: $light-green;
    border-radius: 5px;
    box-shadow: 0 0 6px rgba(116, 183, 187, 0.65);
    transition: $transition;
    color: $black;
    cursor: pointer;
    padding: 5px;
    @include centerAll();

    &:hover {
        background: $hovered_green;
    }
}

.active {
    background: $green;
    color: $white;
}
