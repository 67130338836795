.courses__progress-container {
  display: flex;
  flex-grow: 1;
  padding-right: 20px;
}

.progress {
  display: flex;
  margin: 0 50px;
  flex-grow: 1;
  align-self: center;
  min-width: 50px;
  position: relative;
  &__line {
    height: 22px;
    border: 1px solid #2B2A29;
    background-color: rgba(0,0,0,0.1);
    border-radius: 15px;
    flex-grow: 1;
    overflow: hidden;
    transition: 0.3s;
    &:hover {
      box-shadow: 0 0 6px var(--main-green);
    }
  }

  &__width {
    height: 100%;
    background-color: var(--main-green);
    border-radius: 15px;
    display: inline-block;
  }

  &__percents {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 16px;
  }
}