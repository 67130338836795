.chess-display {

  &__scroll-hint {
    animation: scrollHint 0.8s infinite;
    position: absolute;
    width: 15px;
    height: 15px;
    bottom: 18px;
    right: 18px;
    border: 1px solid var(--main-green);
    background-color: white;
    background-image: url('../../../../../assets/img/arrow-down.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 10px;
    z-index: 1;
  }

  &__test-label-checkbox {
    display: flex;
    //border: 1px solid black;
    padding: 10px 10px 10px 35px;
    border-radius: 13px;
    cursor: pointer;
    position: relative;
    line-height: 18px;

    &:hover {
      background-color: rgba(87,167,178,0.2)
    }

    &::before {
      content: '';
      position: absolute;
      width: 16px;
      height: 16px;
      left: 8px;
      top: 50%;
      transform: translateY(-50%);
      border: 2px solid var(--main-green);
    }
  }

  &__test-checkbox:checked + label::before {
    background-color: var(--main-green);
    background-image: url("../../../../../assets/img/done.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  &__test-item {
    margin: 8px 0;
    cursor: pointer;
    max-width: 600px;
  }

  &__test-list_webinar {
    border-bottom: none !important;
  }

  &__test-list--checkbox {
    //border-bottom: 1px solid gray;
  }
}

@keyframes scrollHint {
  0% {
    transform: translateY(-1px);
  }

  100% {
    transform: translateY(1px);
  }
}