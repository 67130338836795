@media screen and (max-width: 650px) {
  .description {
    padding: 10px 15px 25px 15px;
    background-color: #F5F5F5;
    border: none;
    &__title {
      display: none;
    }

    &__text {
      line-height: 20px;
    }
  }
}