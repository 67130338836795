.label {
    &-body {
        height: 14px;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        box-sizing: border-box;
        padding: 3px 4px;
    }

    &-text {
        font-family: Century Gothic;
        font-style: normal;
        font-weight: bold;
        font-size: 9px;
        line-height: 8px;
        margin: 0;
    }
    
}
