@import "../../../../style/variables.scss";

.hidesidebar {
    &-wrapper {
        width: 20px;
        height: 20px;
        padding: 2.5px;
        border: 0.1px solid $light-grey;
        border-radius: 2.5px;
        cursor: pointer;
        transition: $transition;
        background: rgb(227, 227, 227);

        &:hover {
            background: rgb(197, 197, 197);
            box-shadow: 0 0 5px rgba(100, 100, 100, 0.3);
        }
    }

    &-body {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    &-icon {
        transition: $transition;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: rotate(0deg);
        & svg{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        &.active{
            transform: rotate(180deg);
        }
    }
}