.login {
  width: 380px;
  background-color: var(--main-white);
  border-radius: 20px;
  position: relative;
  box-shadow: 0 0 15px rgba(0,0,0,0.2);

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__title,
  .bold {
    color: var(--text-white);
    font-size: 20px;
    font-weight: lighter;
    text-align: center;
  }

  .bold {
    font-weight: bold;
  }

  &__header {
    padding: 15px 0;
    background-color: #48A69E;
    border-top-left-radius: 17px;
    border-top-right-radius: 17px;
  }

  &__body {
    @media (min-width: 1280) {
      width: 85%;
    }
    margin: 0 auto;
    padding: 20px 0;
  }

  &__enter-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  &__enter-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    padding: 8px 6px 8px 10px;
    margin: 5px 0;
    border: 1px solid black;
    border-radius: 12px;
    width: 270px;
    box-sizing: border-box;

    &:hover {
      background-color: rgba(15,15,15,0.1);
    }
  }

  &__enter-button {
    cursor: pointer;
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    &:hover {
      opacity: 0;
      background-color: initial;
    }
  }

  &__enter-text {
    cursor: default;
    margin-left: 20px;
    font-size: 14px;
  }

  &__enter-logo {
    display: flex;
    width: 20px;
    height: 20px;
  }

  &__navigation {
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
    padding-top: 5px;
  }

  &__navigation-link {
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    color: #48A69E;
    text-decoration: underline;
    margin: 0 4px;
    &--big {
      font-size: 16px;
    }
    &--small {
      font-size: 14px;
    }
    &:hover {
      color: #5B5B5B;
    }
  }

  &__navigation-wrapper {
    margin-top: 10px;
    text-align: center;
  }

  &__approve {
    padding: 10px 20px;
    font-size: 12px;
    text-align: center;

    &-link {
      text-decoration: underline;
      font-size: 12px;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 280px;
    margin: 0 auto;
    align-self: center;
  }

  &__input {
    width: 280px;
    padding: 8px 12px;
    border: 1px solid #898989;
    border-radius: 10px;
    box-sizing: border-box;

    &--password {
      padding: 8px 30px 8px 12px;
    }

    &:hover,
    &:focus {
      border-color: rgb(67,67,67);
      box-shadow: 0 0 4px rgba(13,13,13,0.25);
    }
  }

  &__forgot-link {
    cursor: pointer;
    font-size: 14px;
    color: #5B5B5B;
    font-weight: bold;
    text-decoration: underline;
    align-self: flex-start;
    margin-bottom: 10px;
    &:hover {
      color: #48A69E;
    }
  }

  &__registration-link {
    font-size: 14px;
    margin: 20px 0;
  }

  &__button {
    background-color: #48A69E;
    color: var(--text-white);
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 14px;
    padding: 8px 0;
    width: 280px;
    border-radius: 8px;
    &--red {
      background-color: #D44A3A;
    }
    &:hover {
      background-color: rgb(52,146,138);
    }
    &:disabled {
      background-color: grey;
      &:hover {
        background-color: grey;
      }
    }
  }

  &__reset-title {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 20px;
    margin-top: 30px;
  }

  &__error {
    margin: 10px 0;
    color: #D44C36;
    font-size: 12px;
  }

  &__label {
    position: relative;
  }

  &__form-item {
    margin-bottom: 10px;
  }

  &__password-button {
    position: absolute;
    z-index: 2;
    right: 10px;
    top: calc(50% + 2px);
    transform: translateY(-50%);
  }

  &__form-list {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}