@import '../../../style/variables.scss';

.pagination{
    &-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    &-body{
        flex: 0 0 95%;
        width: 100%;
    }

    &-buttons{
        box-sizing: border-box;
        min-height: 46px;
        width: 100%;
        overflow: hidden;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: $transition;

        &-start,
        &-end{
            min-width: 46px;
            padding: 0 7px;
        }
    }

    &-scrollable{
        box-sizing: border-box;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        transition: $transition;
        display: flex;
        align-items: center;
        & > * {
            margin: 0 7px;
        }
    }
}

.buttons{
    &-show{
        opacity: 1;
        pointer-events: all;
    }

    &-hide{
        opacity: 0;
        pointer-events: none;
    }
}