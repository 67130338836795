.chess__overlay {
  background-color: transparent;
  position: absolute;
  top: 6px;
  bottom: 6px;
  left: 6px;
  right: 6px;
  z-index: 10;
}

//calc(100% - 12px)
