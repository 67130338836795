.buy-course {
  &__header {
    display: flex;
    justify-content: center;
    background: linear-gradient(90deg, rgba(99, 168, 172, 1) 0%, transparent 100%);;
    padding: 75px 0 95px 0;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 234px;
      height: 450px;
      background-size: cover;
      background-image: url("../../../../assets/img/knight.svg");
      z-index: -1;
      background-repeat: no-repeat;
    }
    &-buttons-container {
      margin-top: 20px;
    }
  }

  &__image-container {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -2;
  }

  &__image {
    object-fit: cover;
    object-position: center;
    width: 100%;
  }

  &__header-container {
    display: flex;
  }

  &__header-info-container {
    width: 50%;
    &--center {
      display: flex;
      align-items: center;
      width: 28%;
      margin-left: auto;
    }
  }

  &__header-title {
    margin: 0;
    font-size: 70px;
    line-height: 88px;
    font-weight: bold;
  }

  &__header-info-list {
    display: flex;
    margin-top: 10px;
    margin-bottom: 30px;
  }

  &__header-info-item {
    margin-right: 8px;
    position: relative;
    font-weight: bold;
    line-height: 17px;
    padding: 4px 8px;
    border-radius: 6px;
    background-color: var(--main-white);
    color: var(--main-green);
  }

  &__header-description {

  }

  &__header-preview-button {
    cursor: pointer;
    background-color: var(--main-white);
    font-weight: bold;
    padding: 12px 17px 12px 50px;
    border-radius: 8px;
    position: relative;
    border: 2px solid transparent;
    transition: 0.5s;
    &::before {
      position: absolute;
      transition: 0.5s;
      content: '';
      left: 17px;
      top: 50%;
      transform: translateY(-50%);
      background-image: url("../../../../assets/img/play_black.svg");
      width: 17px;
      height: 14px;
      background-repeat: no-repeat;
      background-position: center;
    }

    &:hover {
      background-color: transparent;
      color: white;
      padding: 12px 20px 12px 52px;
      border: 2px solid var(--main-white);
      box-shadow: 0 0 10px rgba(250,250,250,0.3);
      &::before {
        background-image: url("../../../../assets/img/play_white.svg");
      }
    }
  }

  &__header-buttons-container {
    display: flex;
  }

  &__button {
    background-color: var(--main-white);
    color: var(--main-green) !important;
    padding: 14px 20px 14px 20px;
    flex-grow: 1;
    font-size: 18px;
    cursor: pointer;
    text-align: center;
    margin-right: 20px;
    border-radius: 14px;
    transition: 0.5s;
    font-weight: bold;

    &:last-child {
      margin: 0;
    }

    &:hover {
      background-color: var(--main-green);
      color: var(--main-white) !important;
      box-shadow: 0 0 25px var(--main-green-a);
    }

    &--red {
      background-color: var(--red);
      color: var(--main-white) !important;

      &:hover {
        background-color: rgb(213, 92, 84);
        box-shadow: 0 0 25px rgb(213, 92, 84);
      }
    }
  }
}