.support-form {
  background-color: white;
  padding: 25px 20px;
  max-width: 500px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  position: relative;

  &__list {
    display: flex;
    flex-direction: column;
  }

  &__item {
    display: flex;
  }

  &__label {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: normal;
    margin: 0 10px 15px;
    flex-grow: 1;

    &--half {
      width: 48%;
    }
  }

  &__input {
    font-size: 16px;
    font-weight: normal;
    border-radius: 10px;
    margin-top: 5px;
    border: 2px solid #cccccc;
    padding: 8px 15px;
    box-sizing: border-box;
    resize: none;

    &:focus,
    &:hover {
      border-color: var(--main-green);
    }
  }

  &__button {
    font-size: 16px;
    padding: 10px 15px;
    margin: 0 auto 0 auto;
    width: 40%;
  }

  &__link {
    font-size: 16px;
    margin: 0 15px 15px 15px;
    color: var(--main-green);
    text-decoration: underline;
  }

  &__close {
    cursor: pointer;
    z-index: 10;
    position: absolute;
    padding: 20px;
    right: -10px;
    top: -10px;
    & path {
      fill: #cccccc;
    }
  }
}

@media screen and (max-width: 650px) {
  .support-form {
    min-width: 330px;
    padding: 30px 10px 20px 10px;
    border-radius: 0;

    &__item {
      flex-direction: column;
    }

    &__label {
      margin: 0 5px 10px;
      &--half {
        width: auto;
      }
    }
  }
}