.video-display {
  display: flex;
  flex-direction: column;
  //background-color: #6DC2BB;
  width: 100%;
  min-height: 300px;
  text-align: center;
  //min-height: 400px;
  font-size: 26px;
  color: var(--text-white);
  max-height: 100vh;
  //background-color: rgba(0,0,0,0.2);
  position: relative;

  &--absolute {
    position: absolute;
    top: 0;
    bottom: 400px;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: rgba(0,0,0,1);

    button {
      z-index: 1001;
      color: var(--text-white);
    }
  }
}

.videoWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.cover {
  position: absolute;
  height: calc((680px - (400px * (9 / 16))) / 2);
  width: 100%;
  background: green;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-white);
  font-size: 20px;
  text-align: center;
}

header {
  top: 0;
}

footer {
  bottom: 0;
}


.no-video {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20vh 5px;
  flex-grow: 1;
  position: relative;
  justify-content: center;
  box-sizing: border-box;
  background-color: rgba(255,255,255,0.25);

  &__image {
    height: 100px;
  }

  &__text {
    font-size: 1.4rem;
    margin: 25px 0;
    color: var(--text-black);
    box-sizing: border-box;
    font-weight: bolder;
    text-align: center;
  }
}