@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");

* {
    box-sizing: border-box;
    font-family: var(--font-family);
}

:root {
    --padding: 5px;
    --border-current-color: rgb(46, 46, 46);
    --border-prev-next-color: var(--border-current-color);
    --border-current-size: 0.7px;
    --border-prev-next-size: var(--border-current-size);
    --border-current-type: solid;
    --border-prev-next-type: var(--border-current-type);
    --transition: .3s ease;
    --currentMonthColor: rgb(199, 199, 199);
    --prevAndNextMonthColor: rgb(151, 151, 151);
    --navigationColor: rgb(138, 138, 138);
    --selectedDay: crimson;
    --currentDayColor: cornflowerblue;
    --text-color: black;
    --hoverColor: rgb(211, 81, 107);
    --hoverTextColor: white;
    --selectedDayTextColor: rgb(53, 6, 15);
    --currentDayTextColor: rgb(25, 54, 109);
    --font-family: "Open Sans", sans-serif;
}

.chocolate {
    --border-current-color: #21222e;
    --border-prev-next-color: var(--border-current-color);
    --currentMonthColor: #e7dbcb;
    --prevAndNextMonthColor: #ad9c90;
    --navigationColor: #ad9c90;
    --selectedDay: #5c61ff;
    --selectedDayTextColor: #ffffff;
    --hoverColor: #8d90fd;
    --currentDayColor: #ffffff;
    --currentDayTextColor: #21222e;
    --text-color: #21222e;
}

.christmas {
    --border-current-color: #263e3e;
    --border-prev-next-color: var(--border-current-color);
    --currentMonthColor: #f2ebdb;
    --prevAndNextMonthColor: #d3b692;
    --navigationColor: #d3b692;
    --selectedDay: #cd303b;
    --selectedDayTextColor: #ffffff;
    --hoverColor: #db6970;
    --currentDayColor: #ffffff;
    --currentDayTextColor: #263e3e;
    --text-color: #263e3e;
}

.greenToy {
    --border-current-color: #1a5a40;
    --border-prev-next-color: var(--border-current-color);
    --currentMonthColor: #d2e4e3;
    --prevAndNextMonthColor: #6fa16d;
    --navigationColor: #d2e4e3;
    --selectedDay: #99b1b8;
    --selectedDayTextColor: #ffffff;
    --hoverColor: #aac5ce;
    --currentDayColor: #ffffff;
    --currentDayTextColor: #1a5a40;
    --text-color: #1a5a40;
}

.unicorn {
    --border-current-color: #c9b1ff;
    --border-prev-next-color: var(--border-current-color);
    --currentMonthColor: #fff3ad;
    --prevAndNextMonthColor: #bcffbc;
    --navigationColor: #ffcaf2;
    --selectedDay: #ffb2b1;
    --selectedDayTextColor: #c95957;
    --hoverColor: #fda2a0;
    --currentDayColor: #ffffff;
    --currentDayTextColor: #c95957;
    --text-color: #a686f3;
}

.aqua {
    --border-current-color: #00827e;
    --border-prev-next-color: var(--border-current-color);
    --currentMonthColor: #99e6b2;
    --prevAndNextMonthColor: #69d3b0;
    --navigationColor: #7ad2a8;
    --selectedDay: #1f9688;
    --selectedDayTextColor: #fff;
    --hoverColor: #39b4a6;
    --currentDayColor: #ffffff;
    --currentDayTextColor: #1f9688;
    --text-color: #00827e;
}

.autumn {
    --border-current-color: #cb3503;
    --border-prev-next-color: var(--border-current-color);
    --currentMonthColor: #feb845;
    --prevAndNextMonthColor: #fb931f;
    --navigationColor: #fb931f;
    --selectedDay: #e65d0a;
    --selectedDayTextColor: #fff;
    --hoverColor: #f78b49;
    --currentDayColor: #ffffff;
    --currentDayTextColor: #fb931f;
    --text-color: #cb3503;
    --backgroundColor: #fb931f;
}

.night {
    --border-current-color: #1f234e;
    --border-prev-next-color: var(--border-current-color);
    --currentMonthColor: #4d507f;
    --prevAndNextMonthColor: #2f325d;
    --navigationColor: #404472;
    --selectedDay: #fde978;
    --selectedDayTextColor: #1f234e;
    --hoverColor: #1f234e;
    --currentDayColor: #ffffff;
    --currentDayTextColor: #1f234e;
    --text-color: #fff;
    --backgroundColor: #2f325d;
}

.abchess {
    --border-current-color: #fff;
    --border-prev-next-color: var(--border-current-color);
    --currentMonthColor: #fff;
    --prevAndNextMonthColor: #cecece;
    --navigationColor: #34928a;
    --selectedDay: #fde978;
    --selectedDayTextColor: #000;
    --hoverColor: hsl(175, 47%, 65%);
    --currentDayColor: #74b7bb;
    --currentDayTextColor: #1f234e;
    --text-color: #000;
    --backgroundColor: #fff;
}

.calendarWrapper *{
    transition: var(--transition);
}

.calendarWrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    padding: 7px;
    padding-top: 0;
    background: var(--backgroundColor);
}

.calendar {
    max-width: 240px;
    max-height: 310px;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid var(--navigationColor);
    border-top: 0px solid;
    overflow: hidden;
}

.daysItem {
    flex: 0 0 14.28%;
    height: 30px;
    padding: var(--padding);
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.calendarItem {
    flex: 0 0 14.28%;
    min-height: 30px;
    padding: calc(var(--padding) / 2);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.calendarItem:hover .content {
    background: var(--hoverColor);
}

.content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text-color);
    font-weight: bold;
    border-radius: 4px;
}

.navigation {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    background: var(--navigationColor);
    position: relative;
    z-index: 3;
}

.modalWrapper {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: -1;
}

.modalItem {
    transition: var(--transition);
    position: absolute;
    top: -200%;
    left: 0;
    border-radius: 0 0 10px 10px;
    overflow: hidden;
}

.showModal {
    top: 100%;
}

.yearPickerWrapper {
    width: 100%;
    padding: calc(var(--padding) * 2);
    background: var(--navigationColor);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.years {
    flex: 0 0 70%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
}

.months {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
}

.year {
    flex: 0 0 20%;
    display: flex;
    justify-content: center;
}

.yearText {
    font-size: 12px;
    color: #fff;
    cursor: pointer;
}

.initYear {
    font-weight: 700;
}

.button {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 var(--padding);
}

.monthYear {
    flex: 0 0 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.text {
    color: #fff;
    transition: var(--transition);
}

.text:hover {
    transform: scale(1.1);
}

.button svg {
    width: 14px;
    height: 14px;
}

.currentMonth .content {
    font-weight: 500;
    cursor: pointer;
    background: var(--currentMonthColor);
    border: var(--border-current-size) var(--border-current-type) var(--border-current-color);
}

.otherMonth .content {
    background: var(--prevAndNextMonthColor);
    border: var(--border-prev-next-size) var(--border-prev-next-type) var(--border-prev-next-color);
}

.currentDay .content {
    font-weight: bold;
    background: var(--currentDayColor);
}

.currentDay .number {
    color: var(--currentDayTextColor);
}

.selectedDay .content {
    background: var(--selectedDay);
}

.selectedDay .number {
    color: var(--selectedDayTextColor);
}

.number {
    position: absolute;
    z-index: 1;
    color: var(--text-color);
    cursor: pointer;
}

.calendarItem:hover .number {
    color: var(--hoverTextColor);
}

.topLeft {
    top: var(--padding);
    left: var(--padding);
}

.topCenter {
    top: var(--padding);
    left: 50%;
    transform: translateX(-50%);
}

.topRight {
    top: var(--padding);
    right: var(--padding);
}

.centerLeft {
    top: 50%;
    left: var(--padding);
    transform: translateY(-50%);
}

.center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.centerRight {
    top: 50%;
    right: var(--padding);
    transform: translateY(-50%);
}

.bottomLeft {
    bottom: var(--padding);
    left: var(--padding);
}

.bottomCenter {
    bottom: var(--padding);
    left: 50%;
    transform: translateX(-50%);
}

.bottomRight {
    bottom: var(--padding);
    right: var(--padding);
}

.button:hover {
    background: var(--hoverColor);
}

.button svg path,
.button svg {
    transition: var(--transition);
    color: #34928a;
    fill: #34928a;
}

.button:hover svg path,
.button:hover svg {
    color: #fff;
    fill: #fff;
}

.button {
    background: var(--currentMonthColor);
}

@media(max-width: 541px){
    .calendar{
        max-width: 80%;
        max-height: 400px;
    }

    .button{
        width: 30px;
        height: 30px;
    }

    .text{
        font-size: 20px;
    }

    .yearText{
        font-size: 18px;
    }

    .calendarItem{
        min-height: 40px;
    }
}

@media (max-width: 260px) {
    .navigation {
        padding: 5px;
    }
    .button {
        transform: scale(0.8);
    }
}
