@media screen and (max-width: 650px) {
  .public-slider {
    padding-bottom: 15px;
  }

  .public-slider__options {
    width: 100%;

    .slider-position-container {
      margin-bottom: 0;
    }

    .slider-position {
      width: 7px;
      height: 7px;
      border-radius: 50%;
    }
  }

  .public-slider__header-button {
    display: none;
  }

  .public-slider__container {
    padding: 0;
  }

  .public-slider__list {
    padding-bottom: 15px;
  }

  .public-slider__header {
    justify-content: center;
    padding: 0 35px;
  }

  .public-slider__card {
    max-width: 248px;
    margin: 10px auto !important;
  }
}