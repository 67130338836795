@media screen and (max-width: 1250px){
  .header-profile__card {
    right: -35px;
    transform: none;
    left: auto;
  }
}

@media screen and (max-width: 970px) {
  .header-profile__button--mobile,
  .navigation__item--mobile,
  .header__link--mobile {
    display: none;
  }
  .header-profile__navigation-item--only-mobile {
    display: flex;
  }
}

@media screen and (max-width: 800px) {
  .header__logo {
    height: 24px;
    width: 17px;
    background-image: url("../../assets/img/logo_single.png");
    // Не трогай это на новый год!
    &::before {
      content: '';
      position: absolute;
      width: 16px;
      height: 16px;
      background-size: contain;
      transform: rotate(35deg);
      top: -10px;
      left: 7px;
    }

  }
}

@media screen and (max-width: 700px) {
  .header {
    box-shadow: 0 4px 4px rgba(30, 30, 30, 0.15)
  }

  .header__item.navigation {
    //max-width: none;
    flex-grow: 1;
    //margin: 0 auto 0 10px;
  }

  .header__container {
    justify-content: space-between;
    padding: 5px 15px;
  }

  .header__link {
    font-size: 14px;
    padding: 8px 2px;
  }

  .navigation {
    max-width: 160px;
    box-sizing: border-box;
    justify-content: flex-start;
  }

  .navigation__item {
    margin-right: 6px;
    //margin-left: auto;
  }

  .header__search-label {
    padding: 0;
    box-sizing: border-box;
  }

  //.header__search-input {
  //  display: none;
  //}

  .header-profile__card {
    padding: 20px 10px 15px 10px;
    top: 52px;
  }

  .header-profile__item {
    margin: 0;
  }


  .header__enter-block {
    display: flex;
  }

  .header__enter {
    font-size: 14px;
  }

  .header__enter-block {
    margin-left: auto;
    margin-right: 0;
  }

  .navigation__select {
    & .my-select__title {
      font-size: 14px;
    }

    & .my-select__options-item {
      font-size: 12px;
    }
  }
}


@media screen and (max-width: 1024px) {
  .search {
    flex-grow: 0;
  };
  .header {
    &__enter{
      font-weight: 400;
    }
    &__logo {
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%) scale(1.2);
      left: 50%;
    };
    &__container {
      position: relative;
      padding: 8px 20px !important;
      & > a {
        flex-grow: 1;
      }
    };
  }
}

@media (max-width: 900px){
  .search{
    max-width: 150px;

    &__input{
      width: 100%;
    }
  }
}

@media  screen and (max-width: 600px) {
  .header{
    &__search-logo {
      right: 4px;
      left: auto;
    };
  }
}