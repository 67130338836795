.courses {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &__wrapper {
    max-width: 1200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 20px 20px 0 20px;
    box-sizing: border-box;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }

  &__loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__title {
    font-weight: bold;
    font-size: 18px;
    align-self: flex-start;
  }

  &__navigation {

    &-list {
      display: flex;
      flex-wrap: wrap;
    }

    &-item {
      display: flex;

      &:not(:last-child) {
        border-right: 1px dotted #727271;
      }
    }

    &-link {
      font-size: 16px;
      text-decoration: underline;

      .active {
        color: #48A69E;
      }
    }
  }
}


.courses__container {
  background-color: rgba(0,0,0,0.1)
}

.courses__navigation-list
.courses__navigation-item
.courses__navigation-link {
  padding: 12px 28px;
}

.courses__navigation-list
.courses__navigation-item:first-of-type
.courses__navigation-link {
  padding-left: 0;
}
.courses__list {
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 40px 20px;
  box-sizing: border-box;
  flex-grow: 1;
  width: 100%;
}

.courses__item {
  display: flex;
  position: relative;
  background-color: white;
  margin: 10px 0 50px 0;
  border-radius: 25px;
  box-shadow: 0 0 15px rgba(30, 30, 30, 0.3);
  transition: 0.3s;
  &:hover {
    transform: scale(1.02);
  }
}

.courses__item-image {
  display: block;
  border: none;
  width: 250px;
  height: 170px;
  background-color: var(--main-green);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 18px;
  border-bottom-left-radius: 18px;
}

.courses__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 25px 15px 45px;
  overflow: hidden;
  width: 220px;
}

.courses {
  &__name {
    font-size: 16px;
  }

  &__title {
    font-size: 18px;
  }

  &__count {
    font-size: 16px;
  }

  &__cup {
    display: flex;
    position: absolute;
    right: 0;
    top: -35px;
    width: 155px;
    height: 185px;
    background-image: url('../../../assets/img/cup.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.courses__navigation-link.active {
  color: #48A69E;
}