.custom-overlay{
  height: calc(100% - 96px);
  position: absolute;
  top: 48px;
  width: 100%;
  pointer-events: none;
}

.piece-selecting {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,0.2);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;

  &__container {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 15px 20px;
    border-radius: 10px;
  }

  &__title {
    font-size: 16px;
    padding: 5px 10px;
    border-bottom: 1px solid gainsboro;
  }

  &__item {
    font-size: 16px;
    border-radius: 5px;
    margin: 5px 0;
    padding: 5px 10px;

    &:hover {
      background-color: var(--main-green);
    }
  }
}