.q-container {
  display: flex;
  flex-direction: column;
  background-color: #f4f4f4;
  margin: 10px 0;

  &__title,
  &__answer {
    padding: 12px 15px;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  &__title {
    cursor: pointer;
    font-weight: bold;
  }
}