@import '../../../../../../style/variables.scss';

.exercise-title {
    &__wrapper {
        width: 100%;
        padding: 10px 20px;
        display: flex;
        align-items: flex-start;
        margin-bottom: 10px;
        border-bottom: 1px solid var(--course-border);

        &.hide-title{
            justify-content: center;
        }
    }

    &__exercise {
        flex: 0 0 15%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        &.hide-title{
            flex: 0 0 0;
        }

        &-icon {
            flex: 0 0 35%;

            & svg {
                width: 100%;
            }
        }

        &-counter {
            flex: 0 0 65%;
            text-align: center;
            font-size: 14px;
            line-height: 17px;
            font-weight: 700;
            color: #3c3b37;
        }

        &-title {
            padding: 0 15px;
            flex: 0 0 75%;
            text-align: center;
            font-weight: 700;
            font-size: 17px;
            line-height: 21px;
            color: #3c3b37;
        }

        &-fullsize {
            flex: 0 0 10%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            transform: $transition;

            &.hide-title{
                flex: 0 0 0;
            }

            @media (max-width: 901px) {
                transform: scale(0);
                pointer-events: none;
            }
        }
    }
}
