@media screen and (max-width: 650px) {
  .course-section {
    padding: 20px 0;

    &__menu-button {
      position: fixed;
      display: block;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      bottom: 25px;
      right: 15px;
      background: var(--main-green) url('../../../assets/img/sections.svg') no-repeat center;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.12), 0 3px 3px rgba(0, 0, 0, 0.24);
      cursor: pointer;
      &:hover {
        background-color: #34928a;
      }
    }

    &__wrapper {
      padding: 0 15px;
      flex-direction: column;
      align-items: center;
    }
  }
}