.public-slider {
  background-color: rgb(245,245,245);
  &:last-of-type {
    padding-bottom: 25px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 30px 20px 10px 20px;
    margin: 0 auto;
    max-width: 1200px;
    box-sizing: border-box;
    flex-grow: 1;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 35px;
    width: 100%;
    box-sizing: border-box;

    &-title {
      font-size: 18px;
      font-weight: lighter;
      text-align: center;
    }

    &-button {
      cursor: pointer;
      border: 1px solid black;
      border-radius: 10px;
      padding: 8px 12px;
      transition: 0.3s;
      background-color: var(--main-white);
      &:hover {
        background-color: rgba(0,0,0,0.1);
      }
    }
  }

  &__body {
    display: flex;
    align-items: center;
  }

  &__card {
    background-color: white;
    border-radius: var(--course-border-radius);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(30, 30, 30, 0.3);
    flex-grow: 1;
    margin: 10px 15px !important;
    max-width: 248px;
    width: 100%;
    transition: 0.3s;
    justify-content: space-between;

    &:hover {
      transform: translateY(-5px);
      box-shadow: -4px 4px 12px rgba(30, 30, 30, 0.2);
    }

    &-image {
      display: block;
      border: none;
      min-height: 165px;
      max-height: 165px;
      object-fit: cover;
      width: 100%;
      background-color: rgba(109, 194, 187, 0.25);
      background-position: center;
      background-repeat: no-repeat;
      background-image: url("../../../assets/img/logo_single.png");
      background-size: 30px;
    }

    &-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      padding: 10px 15px;
      flex-grow: 1;
    }

    &-name {
      font-size: 14px;
      margin: 12px 0 5px 0;
    }

    &-title {
      font-weight: bold;
      margin-bottom: 2px;
      font-size: 14px;
    }

  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    padding: 10px 20px;
  }

  &__options {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: -5px;
    justify-content: center;
    left: 50%;
    transform: translateX(-50%);
  }

  &__button {
    position: absolute;
    top: 50%;
    transform: translateY(50%);
    //z-index: unset;
    &--right {
      right: 10px;
    }

    &--left {
      left: 10px;
    }
  }

  &__slide {
    min-width: 254px;
  }

  .public-slider__slide--mobile {
    display: none;
  }

  &__slider {
    width: 100%;
  }

  & .carousel__inner-slide {
    display: flex;
    justify-content: center;
  }
}

.public-slider__card--loading {
  background: #f6f7f8;
  background: linear-gradient(to right, #fafafa 8%, #f4f4f4 38%, #fafafa 54%);
  background-size: 1000px 640px;
  animation: onLoading 1.5s infinite;

  .public-slider {
    &__card-image {
      opacity: 0.3;
      background-image: url("../../../assets/img/logo_single.png");
      background-position: center;
      background-size: 30px;
      background-color: rgba(109, 194, 187, 0.25);
    }

    &__card-title,
    &__card-name,
    &__card-count {
      height: 16px;
      width: 100px;
      background-color: rgba(109, 194, 187, 0.1);
    }

    &__card-title {
      width: 180px;
    }

    &__card-count {
      width: 140px;
    }

  }

}