.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
  overflow: auto;
  background-color: rgba(50, 50, 50, 0.5);
  animation: onOverlayShow 0.3s;

  &__wrapper {
    display: flex;
    position: relative;
    width: calc(100vw - 50px);
    min-height: calc(100% - 105px);
    box-sizing: border-box;
    margin: 80px 25px 25px;
    justify-content: center;
  }

  &__body {
    position: relative;
    max-width: 100%;
    height: fit-content;
  };
}

.close-button {
  cursor: pointer;
  top: -19px;
  right: -19px;
  position: absolute;
  z-index: 10000;

  &--black {
    z-index: 10001;
  }
}

.isCentered {
  align-items: center;
  min-height: calc(100% - 50px) !important;
  margin: 25px !important;
};

@keyframes onOverlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 600px) {
  .overlay__wrapper {
    min-height: calc(100% - 50px);
    margin: 25px;
  };
}