.contacts {
  display: flex;
  align-items: center;
  align-self: center;
  flex-wrap: wrap;
  padding: 65px 10px;
  max-width: 980px;
  width: 100%;
  box-sizing: border-box;
  flex-grow: 1;

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__item {
    display: flex;
    flex-direction: column;
    margin: 15px 0;
    padding: 5px 12px;
    align-self: center;
    text-align: center;
  }

  &__text,
  &__contact,
  &__title {
    font-size: 20px;
    line-height: 32px;
    font-weight: bolder;
  }

  &__container {
    display: flex;
    flex-direction: column;
    text-align: left;
    flex-grow: 1;
    min-width: 300px;
  }

  &__title {
    font-size: 24px;
    margin-bottom: 30px;
    text-align: center;
  }
}


@media screen and (max-width: 700px) {
  .contacts {
    padding: 40px 10px;
    flex-direction: column;

    &__image {
      margin-right: 0;
      margin-bottom: 30px;
    }

    &__item {
      margin: 12px 0;
      padding-left: 10px;
    }

    &__contact {
      margin-left: 0;
    }

    &__text,
    &__contact,
    &__title {
      font-size: 16px;
      line-height: 28px;
      font-weight: bolder;
    }
  }
}