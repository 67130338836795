.chess-display__control-container{
    transition: .4s ease;
    &.show{
        opacity: 1;
        pointer-events: all;
        display: flex;
        align-items: center;

        & button{
            max-height: 40px;
        }
    }
}

.fg-0{
    flex-grow: 0;
}

.chess-display__title{
    word-wrap: break-word;
    overflow: hidden;
    align-self: center;
    padding: 8px 80px 8px 80px;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;

    @media (max-width: 541px){
        padding: 8px 10% 8px 25%; 
    }
    
}