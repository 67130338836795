.language__title {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.language__item {
  padding: 2px 0;
  &:hover {
    background-color: rgba(0,0,0,.1);
  }
}

.language__list {
  padding: 2px;
  text-align: center;
}

.flag {
  display: inline-block;
  width: 20px;
  height: 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border: 1px solid;
  &--ru {
    background-image: url("../../assets/img/ru.png");
  }
  &--en {
    background-image: url("../../assets/img/en.png");
  }
  &--kz {
    background-image: url("../../assets/img/kz.png");
  }
  &--es {
    background-image: url("../../assets/img/es.svg");
  };
  &--tr {
    background-image: url("../../assets/img/tr.svg");
  };
}