.start-screen {
  display: flex;
  width: 100%;
  height: 100vh;
  flex-grow: 1;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  &__image {
    width: 400px;
  }
}

@media screen and (max-width: 650px) {
  .start-screen {
    &__image {
      width: 95%;
    }
  }
}
