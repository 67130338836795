@media screen and (max-width: 650px) {
  .section-grid {
    width: 100%;
    margin-top: 10px;

    &__list {
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
    }

    &__title {
      display: flex;
      justify-content: center;
      margin: 0 0 10px 0;
      padding: 5px 0;
      font-size: 16px;
      border-bottom: 1px solid #34928a;
      border-top: 1px solid #34928a;
      flex-grow: 0;
    }
  }
}