.custom-carousel {
  position: relative;

  &__container {
    display: flex;
    overflow-x: scroll;
    max-width: 100%;
    transition: 0.5s;

    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  &__item {
    display: flex;
  }

  &__button {
    animation: onButtonsShow 0.5s;
    transition: 0.4s;
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 8px 12px;
    background-color: rgba(250,250,250,0.5);
    &:hover {
      background-color: rgba(250,250,250,0.6);
    }
    &:disabled {
      background-color: rgba(250,250,250,0.3);
      & path {
        opacity: 0.5;
        transition: 0.5s;
        fill: var(--gray);
      }
    }

    &--back {
      left: 0;
    }

    &--next {
      right: 0;
    }
  }
}

@keyframes onButtonsShow {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}