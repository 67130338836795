@media screen and (max-width: 650px) {
  .section-navigation {
    width: 100%;
    position: relative;
    z-index: 10;
    overflow-y: auto;

    &__close {
      cursor: pointer;
      position: absolute;
      padding: 25px;
      top: 0;
      right: 0;
    }

    &--mobile {
      position: fixed;
      background-color: white;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 40px 0 20px 0;
    }

    &__list {
      display: flex;
      flex-direction: column;
      border-radius: 0;
      box-shadow: none;
    }

    &__link {
      display: block;
      padding: 10px;
      font-weight: bold;
      text-decoration: none;
    }
  }
}