@media screen and (max-width: 700px) {
  .free-position {
    flex-direction: column;
    border: 0;
    box-sizing: border-box;

    & .chess-display__chessboard {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      box-sizing: border-box;
    }

    &__buttons-container {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 10px;
      border: 0;
      height: auto;
      flex-wrap: wrap;
    }
    &__button {
      margin: 10px 5px 0 5px;
      width: 160px;
      flex-grow: 1;
      box-sizing: border-box;
      padding: 8px 5px;
      font-size: 14px;
    }
  }

  .play-game {
    &__type-container {
      display: flex;
      flex-direction: column;
      padding: 15px 0;
    }

    &__type {
      width: auto;
      padding: 30px 15px;
      font-size: 1.3rem;

      &--active {
        background-size: 20px;
      }
    }
  }
}