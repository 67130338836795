.tasks {
  flex-shrink: 0;
  box-sizing: border-box;
  //background-color: rgba(0,0,0,0.1);
  border-right: 1px solid var(--course-border);
  border-left: 1px solid var(--course-border);

  &__list {
    max-height: 100vh;
    overflow-y: auto;
    scrollbar-color: rgba(10,10,10,0.2) rgba(10,10,10,0.1);
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba(200,200,200,0.2);
      border-left: 1px solid var(--course-border);
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgb(200,200,200);
      border-left: 1px solid var(--course-border);
    }
  }

  &__item {
    display: flex;
    align-items: center;
    position: relative;
    padding: 10px 15px;
    border-bottom: 1px solid var(--course-border);
    cursor: pointer;
    transition: 0.4s;

    &:hover {
      background-color: rgb(245,245,245);
    }

    &--active {
      background-color: white;
    }
  }

  &__image {
    position: relative;
    display: inline-block;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background-color: var(--main-green);
    margin-right: 20px;
    flex-shrink: 0;
    background-position: center;
    background-size: 100px;
    background-repeat: no-repeat;
  }

  &__info {
    flex-grow: 1;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-size: 16px;
    margin-bottom: 6px;
  }

  &__count {
    color: #898989;
    font-size: 14px;
  }

  &__not-free {
    //background-color: black;
  }

  &__overlay {
    position: absolute;
    top: -8px;
    bottom: -8px;
    left: -13px;
    right: -13px;
    background-color: rgba(0,0,0,0.4);
    background-image: url("../../../../assets/img/lock_white.svg");
    background-position: center;
    background-repeat: no-repeat;
    z-index: 10;
  }
}