.test {
  position: relative;
  min-width: 25%;

/*&--hidden {
    display: none;
    transform: translateX(4000px);
  }*/

  &__list {
    max-height: 100vh;
    overflow-y: auto;
    scrollbar-color: rgba(10,10,10,0.2) rgba(10,10,10,0.1);
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba(200,200,200,0.2);
      border-left: 1px solid var(--course-border);
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgb(200,200,200);
      border-left: 1px solid var(--course-border);
    }
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 12px 15px;
    border-bottom: 1px solid var(--course-border);
    position: relative;
    cursor: pointer;
    transition: 0.4s;
    //
    //&.active {
    //  background-color: var(--main-white);
    //  padding-left: 32px;
    //}

    &--head {
      background-color: rgba(0,0,0,0.1)
    }

    &:hover {
      background-color: rgb(245,245,245);
    }

    &--active {
      background-color: var(--main-white);
      padding-left: 32px;
    }
  }

  &__video-complete {
    position: absolute;
    width: 15px;
    height: 15px;
    right: -20px;
    top: 50%;
    transform: translateY(-50%);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("../../../../assets/img/done.png");
  }

  &__video-title {
    position: relative;
    max-width: 75%;
  }

  &__image {
    display: inline-block;
    width: 35px;
    height: 35px;
    background-color: var(--main-green);
    margin-right: 20px;
  }

  &__info {
    flex-grow: 1;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    padding: 5px 0;
  }

  &__title {
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  &__count {
    color: #898989;
    font-size: 14px;
  }

  &__active-decoration {
    display: inline-block;
    animation: onSolved 1s;
    max-width: 22px;
    width: 22px;
    background-color: var(--main-green);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }

  &__video-lesson {
    display: flex;
    align-items: center;
    padding: 17px 15px;
    color: var(--text-white);
    font-size: 16px;
    background-color: var(--main-green);
    border-bottom: 1px solid var(--course-border);
    cursor: pointer !important;

    &--active {
      background-color: #34928a;
    }

    &:hover {
      cursor: default;
      background-color: #34928a;
    }
  }
}

.test--loading {

  .tasks__image {
    background-color: rgba(109, 194, 187, 0.65);
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../../../assets/img/logo_single.png");
    background-size: 15px;
    opacity: 0.2;
  }

  .test__item--head {
    background: rgba(0,0,0,0.1);
    background: linear-gradient(to right, rgba(0,0,0,0.1) 8%, #f4f4f4 38%, rgba(0,0,0,0.1) 54%);
    background-size: 1000px 640px;
    animation: onLoading 1.5s infinite;
  }

  .tasks__title {
    height: 18px;
    width: 140px;
    background-color: rgba(109, 194, 187, 0.1);
  }

  .tasks__count {
    height: 14px;
    width: 100px;
    background-color: rgba(109, 194, 187, 0.1);
  }

  .test__title {
    height: 18px;
    width: 140px;
    background-color: rgba(109, 194, 187, 0.1);
  }

  .test__item {
    background: white;
    background: linear-gradient(to right, white 8%, #f4f4f4 38%, white 54%);
    background-size: 1000px 640px;
    animation: onLoading 1.5s infinite;
  }

  .site__logo {
    opacity: 0.2;
    background-image: url("../../../../assets/img/logo_single.png");
  }
}

@keyframes onSolved {
  from {
    width: 0;
  }
  to {
    width: 50%;
  }
}