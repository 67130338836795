@import "../../../../style/variables.scss";
@import "../../../../style/mixins.scss";

.wrapper {
    max-width: 850px;
    box-shadow: 0px 4px 8px 0px #00000026;
    border-radius: 10px;

    @media (max-width: 651px) {
        max-width: 100%;
        margin: 0 12px;
        width: 100%;
    }

    &__header {
        background: $dark_green;
        padding: 12px 0;
        border-radius: 10px 10px 0 0;
        @include centerAll();
        &-title {
            font-family: "Century Gothic";
            font-weight: bold;
            font-size: 20px;
            line-height: 20px;
            color: $white;
            margin: 0;
        }
    }
    &__body {
        @include centerAll();
        position: relative;

        &-register,
        &-motivation {
            flex: 0 0 50%;
            padding: 45px 20px;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            height: 100%;
        }

        &--with-padding &-motivation {
              padding: 0 10px 0 40px;
              margin: 0;
        }

        &--with-padding &-register{
              padding: 10px 20px 25px 20px;
        }

        &-registration {
            padding-left: 10px;
        }

        &-motivation {
            padding: 0 10px 0 40px;
            margin: 20px 0;
            &__title {
                font-weight: 700;
                font-size: 14px;
                color: #757575;
                margin-bottom: 20px;
                margin-left: 30px;
            }
            &__paragraph {
                font-size: 14px;
                font-weight: 400;
                color: #757575;
                margin-left: 30px;
                &:not(:last-child) {
                    margin-bottom: 15px;
                }
            }

            &__list {
                margin-bottom: 10px;
                list-style: none;
                
                li {
                    font-size: 14px;
                    font-weight: 400;
                    color: #757575;
                    padding-left: 30px;
                    margin-bottom: 10px;
                }

                &__marker-car {
                    background: url('../../../../assets/img/webinar/car.svg') left center no-repeat;
                }
                &__marker-trophy {
                    background: url('../../../../assets/img/webinar/trophy.svg') left center no-repeat;
                }
                &__marker-smile {
                    background: url('../../../../assets/img/webinar/smile.svg') left center no-repeat;
                }

                .wrapper__body--with-padding & {
                    margin-bottom: 20px;

                    li {
                      margin-bottom: 20px;
                    }
                }
            }
        }

        &-register {
            border-left: 2px solid #ececec;
            &-item {
                position: relative;
            }
            &-item:not(:last-child) {
                // margin-top: 15px;
                margin-bottom: 15px;
            }
            &__buttons {
                display: flex;
                justify-content: space-between;
                align-items: stretch;
                white-space: nowrap;

                @media (max-width: 541px) {
                    flex-direction: column;
                }
            }
            &--padding {
                padding: 0 5px 0 40px;
            }

            @media (max-width: 651px) {
                width: 100%;
            }
            @media (max-width: 768px) {
                width: 100%;
                border-left: none;
                
                &--padding {
                  padding: 10px 0;
              }
            }
        }

        @media (max-width: 651px) {
            flex-direction: column-reverse;
        }
    }

}

.input-wrapper {
    width: 100%;
    position: relative;
    &__input {
        width: 100%;
        background: #eeeeee;
        appearance: none;
        border: 0px solid transparent;
        padding: 10px 15px;
    }
    &__required {
        position: absolute;
        top: 0;
        right: -10px;
        color: $smooth_red;
        font-size: 16px;
        line-height: 18px;
    }
    &__pass-btn {
        position: absolute;
        top: 50%;
        right: 10px;
        font-size: 12px;
        line-height: 1;
        transform: translateY(-50%);
        @include centerAll();
    }
}

.button {
    border-radius: 8px;
    text-align: center;
    font-size: 12px;
    line-height: 10px;
    padding: 12px 13.5px;

    transition: $transition;
    @media (max-width: 541px) {
        font-size: 14px;
        line-height: 12px;
        padding: 10px 15px;
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
    @media (max-width: 768px) {
      &:not(:last-child) {
          margin-right: 10px;
      }
  }
}

.fulled {
    background: #74b7bb;
    color: $white;
    &:hover {
        background: #60989b;
    }
}

.bordered {
    border: 1px solid #74b7bb;
    color: #74b7bb;
    &:hover {
        border: 1px solid #60989b;
        color: #60989b;
    }
}

.flex49 {
    flex: 0 0 45%;
}

.dice {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 15px;
    @include centerAll();
    & svg {
        color: #c4c4c4;
        width: 100%;
        height: 100%;
    }
}

.red {
    color: $smooth_red;
}

.line {
    width: 111%;
    height: 2px;
    margin: 20px 0 20px -20px;
    background: #ececec;
    overflow: hidden;
    @media (max-width: 768px) {
        margin: 20px 0;
        width: 100%;
    }
}

.icons-row {
    display: flex;
    flex-grow: 2;
    max-height: 40px;
    justify-content: space-evenly;
    margin: 10px 0 15px;
  
    svg {
        width: 38px;
        height: 38px;
    }
}

.stream-links-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .connect-button {
    background-color: transparent;
    color: var(--main-green);
    cursor: pointer;
    text-decoration: underline;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    padding: 8px 12px;
    &--red {
      background-color: #D44A3A;
    }
  
    &:hover {
      background-color: transparent;
      color: var(--dark-green);
    }
  
    &:hover:not(.disabled) {
      background: transparent;
    }
  
    &:disabled {
      background-color: var(--gray);
      &:hover {
        background-color: var(--gray);
      }
    }
  }

.body {
    @include centerAll();
    width: 100%;
    height: 100%;
    margin-top: 50px;
    margin-bottom: 100px;
}

.cube_rotate {
    animation-name: rotate;
    animation-duration: .6s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: rotate;
    -webkit-animation-duration: .6s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    transition: rotate .6s ease;
}

@keyframes rotate {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
}

@-webkit-keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

.u-mb-none {
    margin-bottom: 0 !important;
}