.no-access {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20vh 5px;
  flex-grow: 1;
  position: relative;
  justify-content: center;
  box-sizing: border-box;
  background-color: rgba(255,255,255,0.25);

  &__image {
    height: 100px;
  }

  &__text {
    font-size: 1.4rem;
    margin: 25px 0;
    box-sizing: border-box;
    font-weight: bolder;
    text-align: center;
  }

  &__background {
    display: flex;
    position: absolute;
    padding: 15px;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.8;
    filter: blur(5px);
  }

  &__chess-board {
    flex-grow: 1;
    background-image: url("../../../../assets/img/board-image.png");
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__background-text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
  }

  &__background-text {
    font-size: 20px;
    font-weight: bold;
    padding-left: 10px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 700px) {
  .no-access {

    &__background {
      flex-direction: column;
    }

    &__background-text-container {
      width: 100%;
    }

    &__background-text {
      font-size: 14px;
      padding-left: 0;
    }
  }
}