.section-navigation {
  width: 22%;
  min-width: 300px;
  &__list {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 0 0 8px rgba(0,0,0,0.2);
  }

  &__item {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    flex-grow: 1;
  }

  &__link {
    display: flex;
    padding: 10px 40px 10px 15px;
    font-size: 14px;
    word-wrap: anywhere;
    flex-grow: 1;

    &:hover {
      color: #34928a;
    }
  }

  .section-navigation__link {
    &.active {
      border-left: 4px solid var(--main-green);
      background-color: rgba(109, 194, 187, 0.15);
    }
  }

  &__sub-list {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    position: relative;
    transition: 0.5s;
    &::before {
      content: '';
      position: absolute;
      width: 2px;
      height: 100%;
      background-color: var(--main-green-a);
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__sub-link {
    transition: 0.5s;
    display: flex;
    padding: 10px 15px 10px 20px;
    font-size: 14px;
    word-wrap: anywhere;

    &:hover {
      color: #34928a;
    }
  }

  .section-navigation__sub-link {
    &.active {
      position: relative;
      &::before {
        box-shadow: 0 0 0 5px var(--main-white);
        animation: onStartOpacity 0.5s;
        content: '';
        width: 8px;
        height: 8px;
        background-color: var(--main-white);
        border: 2px solid var(--main-green);
        //border-radius: 50%;
        background-position: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        left: -5px;
      }
    }
  }

  &__super-category-group {
    display: flex;
    flex-grow: 1;
    position: relative;
  }

  &__super-category-arrow {
    cursor: pointer;
    display: flex;
    position: absolute;
    width: 35px;
    top: 0;
    bottom: 0;
    right: 0;
    background-image: url("../../../../assets/img/arrow-right-green.svg");
    background-position: center;
    background-repeat: no-repeat;
    &--active {
      transform: rotate(90deg);
    }
  }
}

@keyframes onStartOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}