@media screen and (max-width: 650px) {
  .settings {
    &__list {
      width: 100%;
      padding: 0;
      box-shadow: none;
      border-radius: 0;
      margin-top: 0;
    }

    &__exit-button {
      align-self: flex-end;
    }

    &__text {
      font-size: 14px;
      line-height: 20px;
    }

    &__exit-button {
      font-size: 14px;
      line-height: 20px;
    }
  }
}