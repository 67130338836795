.description {
  padding: 10px 70px;
  color: var(--text-black);

  &__title {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 12px;
  }

  &__text {
    line-height: 26px;
    font-size: 16px;
  }
}