.politics {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  padding: 20px 10px;
  box-sizing: border-box;

  &__main-title {
    font-size: 2rem;
    margin: 5px 0 15px 0;
  }

  &__title {
    font-size: 1.5rem;
    margin: 15px 0;
  }

  &__text {
    font-size: 1rem;
    line-height: 1.8rem;
    margin: 10px 0;
  }

  &__list {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  &__item {
    font-size: 16px;
    line-height: 24px;
    margin: 10px 0;
    padding-left: 25px;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: 10px;
      background-color: black;
      border-radius: 50%;
      width: 6px;
      height: 6px;
      top: 9px;
    }
  }

  & a {
    text-decoration: underline;
    font-size: 16px;
    line-height: 24px
  }
}