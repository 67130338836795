@import "../../style/variables.scss";
.header {
  display: flex;
  box-shadow: 0 0 0 transparent;
  border-bottom: 0px solid transparent;
  background-color: var(--main-white);
  box-sizing: border-box;
  transition: $transition;
  overflow: hidden;
  position: relative;
  z-index: 101;
  &__container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 1200px;
    box-sizing: border-box;
    padding: 0;
    margin: 0 auto;
    flex-grow: 1;
    height: auto;
    max-height: 0px;
    transition: $transition;
  }

  &.show-header {
    border-bottom: 1px solid #9d9e9e;
    box-shadow: 0 4px 4px rgba(30, 30, 30, 0.15);
    overflow: visible;

    & .header__container {
      padding: 8px 0;
      max-height: 100px;
      z-index: 6;
    }
  }

  &__item {
    margin: 0 10px;
    flex-grow: 1;
  }

  &__search-input {
    background-color: #ebecec;
    border: 1px solid #d9dada;
    padding: 8px 15px 8px 45px;
    border-radius: 18px;
    flex-grow: 1;

    &:focus {
      background-color: transparent;
      box-shadow: 0 0 5px #ebecec;
    }
  }

  &__search-label {
    display: flex;
    //max-width: 350px;
    position: relative;
    margin: 0 5px;
  }

  &__search-logo {
    position: absolute;
    background-color: transparent;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
  }

  &__link {
    font-weight: bold;
    cursor: pointer;
    padding: 0 12px;
    font-size: 15px;
    position: relative;

    @media (max-width: 1300px) {
      padding: 2px 9px
    }

    &--gap {
      padding-right: 4px;
    }
  }

  &__logo {
    display: block;
    height: 30px;
    width: 150px;
    background-image: url("../../assets/img/logo.svg");
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;

    // Не трогай это на новый год!
    //&::before {
    //  content: '';
    //  position: absolute;
    //  width: 20px;
    //  height: 20px;
    //  background-image: url("../../assets/img/christmas-hat.png");
    //  background-position: center;
    //  background-repeat: no-repeat;
    //  background-size: contain;
    //  transform: rotate(35deg);
    //  top: -10px;
    //  left: 9px;
    //}
    //&::after {
    //  content: '';
    //  position: absolute;
    //  width: 30px;
    //  height: 100%;
    //  background-image: url("../../assets/img/christmas-tree.svg");
    //  background-position: center;
    //  background-repeat: no-repeat;
    //  background-size: contain;
    //  bottom: 0;
    //  top: 0;
    //  left: -35px;
    //}
  }

  &__enter {
    margin-right: 5px;
    font-size: 15px;
  }

  &__enter-block {
    display: flex;
  }
}

.navigation {
  display: flex;
  align-items: center;
  //max-width: 225px;
  flex: none;
  height: 100%;
}

.profile-section{
  display: flex;
  align-items: center;
}

.profile-name{
  @media (max-width: 541px) {
    display: none;
  }
}

.header-profile {
  display: flex;
  margin-left: auto !important;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 0;

  &__item {
    margin: 0 8px;
    position: relative;

    @media (min-width: 900px) {
      margin-left: 20px;
    }

    &--container {
      position: relative;
    }
  }

  &__person {
    cursor: pointer;
    border: 2px solid #424242;
    border-radius: 50%;

    &:hover {
      border-color: var(--main-green);
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 15px 20px 15px;
    border-radius: 6px;
    top: 56px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    width: 200px;
    background-color: white;
    box-shadow: -4px 8px 14px rgba(30, 30, 30, 0.25);
    z-index: 200;
  }

  &__avatar {
    cursor: pointer;
    display: block;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: rgba(109, 194, 187, 0.45);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    object-fit: cover;

    &--placeholder {
      opacity: 0.3;
      background-image: url("../../assets/img/logo_single.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 30px;
      background-color: rgba(109, 194, 187, 0.45);
    }
  }

  &__button {
    margin-right: 0;
    &--gap {
      margin-bottom: 10px;
    }
  }

  &__name {
    font-weight: bold;
    font-size: 14px;
    margin: 10px 0;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__dotes {
    display: block;
    margin: 12px 0;
    width: 100%;
    flex-grow: 1;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }

  &__navigation {
    width: 100%;
  }

  &__navigation-item {
    display: flex;
  }

  &__navigation-link {
    cursor: pointer;
    padding: 8px;
    flex-grow: 1;
    border-radius: 8px;
    text-align: center;
    &:hover {
      background-color: #ebecec;
    }
  }
}

.navigation__item {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-label {
  position: absolute;
  top: -50%;
  right: 0;
  transform: translate(60%, 0);
}

.header-profile__navigation-item--only-mobile {
  display: none;
}

.navigation__select {
  & .my-select__title {
    font-size: 15px;
  }

  & .my-select__options-item {
    font-size: 14px;
  }
}

.burger {
  &-place {
    width: 25px;
    height: 15px;
  }
  &-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
  }
  &-line {
    &-3,
    &-2,
    &-1 {
      position: absolute;
      transition: $transition;
      width: 100%;
      height: 3px;
      left: 0;
      background: $black;
      border-radius: 1.5px;
    }

    &-1 {
      top: 0;
    }

    &-2 {
      top: 50%;
      transform: translateY(-10%);
    }

    &-3 {
      top: 100%;
    }
  }
}

.open-burger {
  & .burger-line {
    &-1 {
      top: 50%;
      transform: rotate(45deg);
    }

    &-2 {
      top: 50%;
      left: -100%;
      opacity: 0;
    }

    &-3 {
      top: 50%;
      transform: rotate(-45deg);
    }
  }
}

@media (max-width: 1300px){
  .header{
    &__container{
      max-width: 1100px;
    }
  }
}

@media (max-width: 900px) {
  .header{
    &__search-label{
      margin-left: 30px;
    }
  }
}

.header_lang {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  @media (max-width: 1180px) {
    position: relative;
    top: 0;
    right: 0;
    transform: translateY(0);
  }
}

.bordered {
  border: 1px solid #74b7bb;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 8px 16px;
  font-family: "Century Gothic";

  font-weight: bold;
  font-size: 15px;
  line-height: 18px;

  color: #74b7bb;
  transition: $transition;
  box-sizing: border-box;
  max-height: 35px;

  &:hover {
    color: rgb(52, 146, 138);
    border-color: rgb(52, 146, 138);
  }

  @media (max-width: 1024px) {
    border-color: transparent;
  }
}

.fulled {
  background-color: var(--main-green);
  color: var(--text-white);
  border: 1px solid var(--main-green);
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  padding: 8px 12px;
  border-radius: 10px;
  transition: $transition;
  box-sizing: border-box;
  max-height: 35px;

  &:hover {
    background-color: rgb(52, 146, 138);
  }

  &:disabled {
    background-color: var(--gray);
    &:hover {
      background-color: var(--gray);
    }
  }

  @media (max-width: 1024px) {
    display: none !important;
  }
}
