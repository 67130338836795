.course-image {
  position: relative;

  &__course-type {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 14px;
    right: 0;
    background-color: var(--sub-blue);
    color: white;
    padding: 3px 15px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    font-size: 12px;
    user-select: none;

    &--free {
      background-color: var(--gray);
    }

    &--subscription {
      background-color: var(--sub-green);
    }
  }
}