$transition: .4s ease;
$light-grey: #9d9e9e;
$green: #74B7BB;
$dark_green: #62A49E;
$hovered_green: #87d0d4;
$white: #fff;
$black: #000;
$light-green: #ebf9f8;
$success: #60BB52;
$failed:#8E2728;
$smooth_red: #E34C4C;