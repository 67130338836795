.chess-display {

  &__test-list {
    max-height: 100%;
    overflow-y: auto;
    padding: 5px 20px 5px 15px;
  }

  &__test-image {
    max-width: 100%;
    //max-height: 30%;
  }

  &__test-label {
    display: flex;
    //border: 1px solid black;
    padding: 10px 10px 10px 35px;
    border-radius: 13px;
    position: relative;
    line-height: 18px;
    cursor: pointer;

    &--no-border {
      border: none;
    }


    &:hover {
      background-color: rgba(87,167,178,0.2);
    }

    &::before {
      content: '';
      position: absolute;
      width: 16px;
      height: 16px;
      left: 8px;
      top: 50%;
      border-radius: 50%;
      transform: translateY(-50%);
      border: 2px solid var(--main-green);
    }

    &--warn {
      animation: onError 0.6s;
      border-color: darkred;
      background: rgba(100, 0, 0, 0.1);
      border-radius: 18px;

      &:hover {
        background-color: rgba(100,0,0,0.1);
      }
    }
  }

  //&__test-radio:checked + label::before {
  //  background-color: var(--main-green);
  //  background-image: url("../../../../../assets/img/done.png");
  //  background-position: center;
  //  background-size: contain;
  //  background-repeat: no-repeat;
  //}

  &__test-label--warn::before {
    background-image: url("../../../../../assets/img/close-white.svg");
    border-color: darkred;
    background-color: darkred;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  &__test-label--correct {
    background: #95e79f;
    border-radius: 18px;
    ::before {
      background-color: var(--main-green);
      background-image: url("../../../../../assets/img/done.png");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  &__test-label--warn {
    ::before {
      background-image: url("../../../../../assets/img/close-white.svg") !important;
      border-color: darkred !important;
      background-color: darkred !important;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  &__test-item {
    margin: 8px auto;
  }

  &__test-image_webinar {
    max-height: 65px;
  }
}

@media screen and (max-width: 650px) {
  .chess-display {

    &__test-list {
      overflow-y: scroll;
    }
  }
}

@keyframes onError {
  0% {
    transform: translateX(-5px);
  }

  20% {
    transform: translateX(5px);
  }

  40% {
    transform: translateX(-2px);
  }

  60% {
    transform: translateX(2);
  }
  80% {
    transform: translateX(0);
  }
}