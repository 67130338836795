@media screen and (max-width: 750px) {
  .subscription {
    padding: 20px 0;

    &__block {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      flex-grow: 1;
      align-items: center;
      min-width: 285px;
      max-width: 350px;
      min-height: 360px;
      margin: 0 15px 15px 15px;
    }

    &__container {
      margin-top: 50px;
    }

    &__title {
      font-size: 24px;
      margin-top: 15px;
    }

    &__text {
      width: auto;
      font-size: 15px;
      padding: 0 10px;
    }

    &__item-wrapper {
      padding: 15px;
    }

    &__options {

      &--column {
        flex-direction: column;
        margin-bottom: 10px;
      }
    }

    &__options-item {
      font-size: 15px;
      padding: 2px 10px;
    }

    &__additional-message {
      font-size: 14px;
      margin: 15px;
      padding: 12px 20px;
    }

    &__type {
      font-size: 12px;
      margin-bottom: 15px;
    }

    .subscription &__button {
      font-size: 15px;
    }

    &__rate-container {
      margin-bottom: 25px;
    }

    &__rate {
      font-size: 15px;
      line-height: 18px;
    }

    &__additional-item {
      max-width: 460px;
      font-size: 12px;
      line-height: 20px;
      padding-left: 20px;
      margin-right: 0;
      &::before {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        left: 0;
        top: 6px;
      }
    }
    //.overlay {
    //  top: auto;
    //  bottom: auto;
    //  left: auto;
    //  right: auto;
    //  background-color: transparent;
    //}
  }
}

@media screen and (max-width: 400px) {
  .subscription {

    &__block {
      margin: 0 15px 15px 15px;
    }
  }
}