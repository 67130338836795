@media screen and (max-width: 1100px) {
  .buy-course {

    &__content-main {
      width: 64%;
    }

    &__content-bar {
      width: 31%;
      margin-left: auto;
    }
  }
}

@media screen and (max-width: 950px) {
  .buy-course {
    &__content-container {
      flex-direction: column;
    }

    &__content-main {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    &__content-container {
      margin-top: 10px;
    }

    &__content-bar {
      width: 100%;
      margin: 0 auto;
      display: flex;
    }

    &__content-bar-block {
      align-items: center;
      margin-top: 10px;
      &--task-list {
        flex-grow: 1;
        margin-right: 40px;
      }
      &--courses {
        flex-grow: 2;
      }
    }

    &__main-block {
      margin-bottom: 45px;
    }

    &__share-button {
      display: inline-block;
      width: auto;
      position: relative;
      box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      margin-left: 15px;
      background-color: var(--main-green);
      padding: 20px 30px 20px 30px;
      &::before {
        position: absolute;
        content: '';
        background-image: url("../../../assets/img/share_white.svg");
        top: 50%;
        transform: translate(50%, -50%);
        right: 50%;
        width: 30px;
        height: 30px;
        background-position: center;
        background-repeat: no-repeat;
      }

      &:hover {
        box-shadow: 0 3px 15px var(--main-green-a);
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .buy-course {
    &__header-container,
    &__content-container {
      padding: 8px 15px;
    }

    &__title {
      margin-bottom: 15px;
      font-size: 20px;
      line-height: 24px;
    }

    &__text {
      font-size: 15px;
      line-height: 20px;
    }

    &__author-name {
      font-size: 15px;
    }

    &__author-text {
      font-size: 15px;
      line-height: 20px;
    }

    &__main-block {
      margin-bottom: 30px;
    }

    &__content-bar {
      flex-direction: column;
    }

    &__content-bar-block {
      &--task-list {
        flex-grow: 1;
        margin-right: 0;
      }
      &--courses {
        flex-grow: 1;
      }
    }

    &__preview {
      width: 100%;
      height: 100%;
    }

    &__preview-close {
      top: -50px;
      right: 0;
    }
  }

}