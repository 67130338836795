.modal {
  &_container {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    z-index: 9999;
  }
  &_body {
  }
  &_actions {
    display: flex;
    justify-content: center;
    border-top: 1px solid gray;
    &_item {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      &:first-child {
        border-right: 1px solid gray;
      }
      button {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        transition: all .2s ease;
        &:hover {
          background-color: lighten($color: gray, $amount: 30%);
        }
        &:active, &:visited {
          background-color: lighten($color: gray, $amount: 20%);
        }
      }
    }
  }
  &_content {
    display: flex;
    flex-direction: column;
      
    width: 400px;
    background-color: whitesmoke;
    & > div {
      height: 50%;
    }
    &_title {
      &_container {
        text-align: center;
        padding: 10px;

      }
    }
  }
}