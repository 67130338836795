.study-slider {
  padding: 0 35px;
  box-sizing: border-box;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 auto;
  flex-grow: 1;
  &__title {
    font-size: 16px;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;
    margin-bottom: -18px;
    margin-top: 15px;
  }

  &__body {
    position: relative;
  }

  &__card {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: var(--course-border-radius);
    display: flex;
    overflow: hidden;
    background-color: white;
    box-shadow: 0 8px 8px rgba(30, 30, 30, 0.15);
    flex-grow: 1;
    margin: 10px 20px;
    min-height: 120px;

    &-image {
      display: block;
      border: none;
      height: 126px;
      object-fit: cover;
      background-color: rgba(109, 194, 187, 0.25);
      background-position: center;
      background-repeat: no-repeat;
      background-image: url("../../../assets/img/logo_single.png");
      background-size: 30px;
    }

    &-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      padding: 10px 15px;
      width: 100%;
    }

    &-name {
      font-weight: bold;
      font-size: 16px;
      margin: 6px 0;
    }

    &-title{
      margin-bottom: 6px;
    }

    &-count {
      margin-top: 6px;
    }

  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    padding: 10px 20px;
  }

  &__slide {
    min-height: 150px;
  }

}

.study-slider {
  .public-slider__button--right {
    right: -25px;
    top: 60px;
  }

  .public-slider__button--left {
    left: -25px;
    top: 60px;
  }
}

.study-slider__card--loading {
  background: #f6f7f8;
  background: linear-gradient(to right, #fafafa 8%, #f4f4f4 38%, #fafafa 54%);
  background-size: 1000px 640px;
  animation: onLoading 1.5s infinite;

  .study-slider {
    &__card-image {
      width: 190px;
      opacity: 0.3;
      background-image: url("../../../assets/img/logo_single.png");
      background-position: center;
      background-size: 30px;
      background-color: rgba(109, 194, 187, 0.25);
    }

    &__card-title,
    &__card-name,
    &__card-count {
      height: 16px;
      width: 100px;
      background-color: rgba(109, 194, 187, 0.1);
    }

    &__card-title {
      width: 180px;
    }

    &__card-count {
      width: 140px;
    }

  }

}

@media only screen and (max-width: 1000px) {
  .study-slider {
    .public-slider__button--right {
      right: -25px;
      top: 60px;
    }

    .public-slider__button--left {
      left: -25px;
      top: 60px;
    }
  }
}


@keyframes onLoading {
  0%{
    background-position: -468px 0
  }
  100%{
    background-position: 468px 0
  }
}