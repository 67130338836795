.about {
  display: flex;
  flex-direction: column;
  text-align: center;

  &__logo {
    display: flex;
    margin: 50px auto 0 auto;
  }

  &__image-container {
    display: flex;
    background-color: lightgrey;
    position: relative;
  }

  &__image-background {
    flex-grow: 1;
    object-fit: cover;
    height: auto;
    min-height: 700px;
    max-width: 100%;
  }

  &__image-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    color: var(--text-white);
    font-weight: 900;
    padding: 10px;
    box-sizing: border-box;
    text-shadow: 0 0 5px rgba(0,0,0,0.8);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0,0,0,0.3);
  }

  &__description {
    max-width: 960px;
    width: auto;
    align-self: center;
    text-align: center;
    font-size: 16px;
    line-height: 35px;
    font-weight: bold;
    margin-top: 55px;
    padding: 0 10px;
    box-sizing: border-box;
    color: #0f0f0f;
    position: relative;

    &--half {
      display: flex;
      flex-direction: column;
      max-width: 700px;
      width: 100%;
      flex-grow: 1;
      border: 3px solid var(--main-green);
      text-align: left;
      padding: 30px 40px 35px 35px;
      margin: 30px 15px 15px 15px;
      line-height: 35px;
      position: relative;
      border-radius: 20px;
      box-shadow: -25px 25px 40px rgba(50,50,50,0.1);
      align-self: flex-start;
      transition: 0.5s;

      &:nth-of-type(2) {
        align-self: flex-end;
        margin-top: -45px;
        box-shadow: 25px 25px 40px rgba(50,50,50,0.1);
        transform: rotate(0.7deg);
        background-color: white;
        color: var(--text-black);
        &::before {
          content: "";
          height: 50px;
          width: 36px;
          position: absolute;
          right: 25px;
          top: -52px;
          z-index: 2;
          background-repeat: no-repeat;
          background-image: url("../../../assets/img/logo_single.png");
          background-size: contain;
        }
      }
    }
  }

  &__description-title {
    margin-top: 0;
    padding: 0 20px;
    text-align: center;
  }

  &__description-wrapper {
    display: flex;
    width: 80%;
    max-width: 1200px;
    margin: 50px auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 700px) {
  .about {
    &__image-background {
      width: 100%;
      min-height: auto;
    }

    &__description {
      font-size: 16px;
      text-align: left;
      box-sizing: border-box;
      &--half {
        width: 100%;
        max-width: none;
        text-align: left;
        padding: 45px 10px 45px 10px;
        margin: 30px 0 15px 0;
        align-self: center;
        &:nth-of-type(2) {
          align-self: center;
          margin-top: 10px;
          box-shadow: 25px 25px 40px rgba(50,50,50,0.1);
          transform: rotate(0.7deg);
        }
      }
    }

    &__description-title {
      padding: 0 15px;
      box-sizing: border-box;
    }

    &__description-wrapper {
      width: 100%;
      margin: 30px 0;
      padding: 0 15px;
      box-sizing: border-box;
      align-items: center;
    }

    &__image-text {
      font-size: 22px;
    }
  }
}

@keyframes move {
  0% {
    right: 25px;
    top: -53px;
  }
  50% {
    right: 45px;
    top: -59px;
  }
  100% {
    right: 65px;
    top: -53px;
  }
}