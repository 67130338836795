@media screen and (max-width: 900px) {
  .interface {
    flex-direction: column;
    padding-top: 15px;

    &__task {
      border-left: 0;
    }

    &__tasks-container {
      width: 100%;
    }

    &__header-container {
      padding: 0;
    }

    &__hide-button,
    &__full-button {
      display: none;
    }

    &__fullsize,
    &__hidesidebar{
      display: none;
    }
  }

  .course-interface__header-container {
    display: none;
  }
}