.subscription {
  &__list {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 50%;
    align-items: center;
  }

  &__list-item {
    display: flex;
    justify-content: center;
    padding: 15px 20px;
    align-items: center;
    margin: 15px;
    box-sizing: border-box;
    width: 500px;
    border-radius: 12px;
    background: linear-gradient(0, rgba(255,255,255,1) 0%, rgba(220,220,220,0.1) 100%);
    box-shadow: 0 0 15px rgba(0,0,0,0.1);
    border: 2px solid white;
    transition: 0.5s;
    cursor: pointer;
    &--special {
      background: linear-gradient(0, rgba(255,255,255,1) 0%, rgba(116, 183, 187, 0.1) 100%);
    }
    &:hover {
      border-color: var(--main-green);
      box-shadow: 0 0 20px rgba(0,0,0,0.1);
    }
  }

  &__list-container {
    display: flex;
    flex-direction: column;
    margin: 10px;
    text-align: center;
    justify-content: center;
  }

  &__item-text {
    font-size: 1.5rem;
    margin: 5px 0;
  }
}