.lesson-navigation-button {
  cursor: pointer;
  border: 2px solid var(--main-green);
  color: var(--main-green);
  font-weight: bold;
  font-size: 14px;
  border-radius: 10px;
  padding: 16px 0;
  width: 300px;
  flex-grow: 1;
  margin-right: 20px;
  transition: 0.4s;
  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: var(--text-white);
    background-color: var(--main-green);
  }
}

.lesson-navigation-button:disabled {
  background-color: white;
  border-color: lightgrey;
  color: lightgrey;
  &:hover {
    background-color: white;
    border-color: lightgrey;
    color: lightgrey;
  }
}

@media screen and (max-width: 800px) {
  .lesson-navigation-button {
    background-color: var(--main-green);
    color: var(--text-white);
    border-radius: 4px;
    padding: 8px 0;
    margin-right: 15px;
    width: auto;
  }
}