.or {
  display: flex;
  text-transform: uppercase;
  align-items: center;
  margin: 10px 0;
  width: 100%;

  &__dotes {
    display: block;
    flex-grow: 1;
    border-top: 2px solid #A6A6A6;
  }

  &__text {
    padding: 0 20px;
    color: #A6A6A6;
  }
}