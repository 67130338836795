.back-to-button {
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  padding: 10px 15px 10px 20px;
  box-sizing: border-box;
  border: 2px solid var(--main-green);
  transition: 0.3s;
  background-color: white;

  & .arrow {
    display: none;
    border-width: 0;
    margin-right: 10px;
  }

  &:hover {
    padding: 10px 15px;
    background-color: var(--main-green);
    color: var(--text-white);
    //box-shadow: 0 0 10px rgba(0,0,0,0.2);

    & .arrow {
      display: flex;
      border-width: 2px;
      border-color: white;
    }
  }
}