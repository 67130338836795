.mistakes-table {
  width: 100%;
  border-collapse: collapse;
  max-width: 1500px;
  margin: 0 auto;

  &__button {
    width: 50%;
    margin: 0 auto;
  }

  &__header-ceil {
    padding: 10px 15px;
    font-size: 14px;
    text-align: left;
    color: white;
    box-sizing: border-box;
    background-color: var(--main-green);

    &--center {
      text-align: center;
    }
  }

  &__ceil {
    padding: 18px 15px;
    font-size: 14px;
    box-sizing: border-box;
    &--center {
      text-align: center;
    }
  }

  &__row {
    &:nth-of-type(2n+1) {
      background-color: rgba(116, 183, 187, 0.2);
    }
    &:hover {
      box-shadow: 0 0 0 1px var(--main-green);
      //background-color: rgba(206, 206, 206, 0.2);
    }
  }

  &__message {
    padding: 20px 10px;
    font-style: italic;
    text-align: right;
  }
}

.mistakes-table-container {
  overflow-x: auto;
}

@media screen and (max-width: 650px) {
  .mistakes-table {
    &__header-ceil {
      padding: 8px;
      font-size: 14px;
    }

    &__ceil {
      padding: 8px;
      font-size: 14px;
    }
  }
}