.search {
  position: relative;
  
  @media (max-width: 1300px) {
    flex-grow: 0;
  }

  &__results {
    position: absolute;
    background-color: white;
    box-shadow: 0 10px 10px rgba(0,0,0,0.4);
    left: 0;
    right: 0;
    top: 128%;
    z-index: 10;
    border-radius: 0 10px;
    padding: 5px 0 10px 0;
    max-height: 60vh;
    overflow-y: auto;

    scrollbar-color: rgba(10,10,10,0.2) rgba(10,10,10,0.1);
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba(200,200,200,0.2);
      border-left: 1px solid var(--course-border);
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgb(200,200,200);
      border-left: 1px solid var(--course-border);
    }
  }

  &__title {
    margin: 0 15px;
    padding: 12px 0;
    border-bottom: 1px solid rgb(100,100,100);
    font-weight: bold;
    color: rgb(100,100,100);
    cursor: default;
  }

  &__category-item {
    display: flex;
    cursor: pointer;
    padding: 12px 15px;
    &:hover {
      background-color: rgba(109, 194, 187, 0.2);
    }
  }

  &__list {
    padding: 10px 0;
    margin-bottom: 5px;
  }
}