.levelLabel-wrapper {
    transition: 0.2s ease;
    width: 70px;
    height: 70px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    &__title {
        font-size: 35px;
        line-height: 40px;
        font-weight: 400;
        color: #fff;
    }
}

@media (max-width: 769px) {
    .levelLabel-wrapper {
        transform: scale(0.8);
    }
}

