@import '../../../style/mixins.scss';

.profile {
  display: flex;
  flex-direction: column;

  &__content {
    max-width: 1200px;
    padding: 25px 0;
    display: flex;
    align-self: center;
    width: 100%;
  }

  &__info {
    max-width: 700px;
  }

  &__avatar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 200px;
    width: 200px;
    margin-right: 50px;
  }

  &__avatar {
    cursor: pointer;
    display: inline-block;
    opacity: 0.3;
    background-image: url("../../../assets/img/logo_single.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70px;
    background-color: rgba(109, 194, 187, 0.45);
    border-radius: 50%;
    width: 200px;
    height: 200px;

    &--image {
      opacity: 1;
      object-fit: cover;
      object-position: center;
    }
  }

  &__info-list {
    display: flex;
    flex-direction: column;
  }

  &__label {
    display: flex;
    flex-direction: column;
    width: 48%;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 15px;
    position: relative;
  }

  &__input {
    font-size: 16px;
    line-height: 20px;
    font-weight: normal;
    border-radius: 12px;
    margin-top: 5px;
    border: 2px solid #cccccc;
    padding: 8px 15px;
    box-sizing: border-box;
    width: 100%;

    &:focus,
    &:hover {
      border-color: var(--main-green);
    }

    &--select {
      padding: 0 5px;
    }
  }

  &__select-list-item {
    font-size: 14px;
    padding: 8px 15px;
  }

  &__select-title-classes {
    font-size: 16px;
  }

  &__select-list-classes {
    border-radius: 12px;
  }

  &__input:disabled {
    background-color: #C4C4C4;
    border-color: #C4C4C4;
    color: var(--text-white);
  }

  &__info-item {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 5px;
    justify-content: space-between;
    border-top: 1px solid #DBDBDB;
    flex-grow: 1;
    width: 100%;
    &:first-of-type {
      padding-top: 0;
      border-top: 0;
    }

    &--buttons {
      justify-content: flex-end;
    }
  }

  &__avatar-text {
    cursor: pointer;
    margin-top: 10px;

    &:hover {
      text-decoration: underline;
    }
  }

  &__button {
    color: var(--text-white);
    font-size: 16px;
    padding: 10px 18px;
    border-radius: 12px;
    background-color: #C4C4C4;

    &:disabled {
      background-color: #C4C4C4;
    }

    &:hover {
      background-color: #34928a;
    }

    &--save {
      background-color: var(--main-green);
    }
  }

  &__avatar-dropzone {
    text-align: center;
  }

  &__done {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: rgba(109, 194, 187, 0.45);
    background-image: url("../../../assets/img/done.png");
    background-position: center;
    background-size: 15px;
    background-repeat: no-repeat;
  }

  &__cancel {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    background-color: rgba(0,0,0,0.1);
    background-image: url("../../../assets/img/close-white.svg");
    background-position: center;
    background-size: 15px;
    background-repeat: no-repeat;
  }

  &__dropzone-buttons {
    bottom: 25px;
    right: 0;
  }
}

.border {
  border: 1px solid #74b7bb;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 8px 16px;
  font-family: "Century Gothic";

  font-weight: bold;
  font-size: 15px;
  line-height: 18px;

  color: #74b7bb;
  transition: .4s ease;
  max-height: 35px;

  &:hover {
    color: rgb(52, 146, 138);
    border-color: rgb(52, 146, 138);
    text-decoration: none;
  }
}

.date-field{
  width: 48%;

  @media (max-width: 650px) {
    width: 100%;
  }
}

.datepicker{
  position: absolute;
  z-index: 2;
  min-width: 260px;
  top: 105%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  pointer-events: none;
  transition: .4s ease;

  &__close{
    position: absolute;
    right: 20px;
    top: 25%;
    transform: translateY(-200%) scale(1.5);
  }

  &.open{
    opacity: 1;
    pointer-events: all;
  }
}


@media (max-width: 541px) {
  .datepicker{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    transform: translateX(0);
    background: rgba(0, 0, 0, .6);
    @include centerAll();
    z-index: 6;
  }
}

.calendar__icon{
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-40%);
  width: 20px;
  height: 20px;
  color: #cccccc;
  &:hover{
    color:rgb(52, 146, 138)
  }

  & svg{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}