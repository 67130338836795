@media screen and (max-width: 650px) {
  .chess-display__check-button {
    border-radius: 4px;
    padding: 8px 0;
    font-size: 14px;
  }

  .chess-display__test-label-checkbox {
    border-radius: 10px;
  }

  .chess-display__test-list {
    max-height: 110px;
  }
}