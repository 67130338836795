.button {
  background-color: var(--main-green);
  color: var(--text-white);
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  padding: 8px 12px;
  border-radius: 10px;
  &--red {
    background-color: #D44A3A;
  }

  &:hover {
    background-color: rgb(52,146,138);
  }

  &:disabled {
    background-color: var(--gray);
    &:hover {
      background-color: var(--gray);
    }
  }
}