@media screen and (max-width: 650px) {
  .profile {

    &__content {
      flex-direction: column;
    }

    &__avatar-container {
      align-self: center;
      margin-right: 0;
    }

    &__info-item {
      padding: 15px;
      flex-direction: column;
      box-sizing: border-box;

      &--buttons {
        flex-direction: row;
        justify-content: center;
      }
    }

    &__info-list {
      padding: 0 15px;
    }

    &__label {
      width: 100%;
    }

    &__button {
      flex-grow: 1;
    }
  }
}