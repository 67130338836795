.skills {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  max-width: 1200px;
  margin-top: 50px;
  border-bottom: 3px dotted black;
  padding: 0 10px 50px 10px;
  box-sizing: border-box;

  &__title {
    font-size: 28px;
    font-weight: bolder;
    padding: 10px 0;
    margin-bottom: 40px;
  }

  &__list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__item {
    width: 300px;
    max-width: 300px;
    //border: 4px solid #74B7BB;
    border-radius: 15px;
    margin: 10px 10px 50px;
  }

  &__image {
    height: 100px;
    margin-bottom: 30px;
  }

  &__text-container {
    display: flex;
    flex-direction: column;
  }

  &__skill-title {
    font-weight: bolder;
    font-size: 18px;
    margin-bottom: 15px;
  }

  &__text {
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
  }
}