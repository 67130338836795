.settings {
  display: flex;
  flex-direction: column;

  &__list {
    display: flex;
    align-self: center;
    flex-direction: column;
    width: 460px;
    padding: 15px 35px;
    margin: 30px 0;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
    border-radius: 14px;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #C4C4C4;
    padding: 18px;

    &--column {
      flex-direction: column;
      align-items: flex-start;
    }

    &--control {
      padding: 12px 18px;
    }
  }

  &__text {
    font-size: 16px;
    line-height: 20px;
  }

  &__item--exit {
    text-align: center;
    padding: 25px 0 10px 0;
    border-bottom: 0;
    justify-content: center;
  }

  &__exit-button {
    cursor: pointer;
    color: var(--main-green);
    font-weight: bolder;
    font-size: 16px;
    line-height: 20px;

    &:hover {
      text-decoration: underline;
    }
  }

  &__colors {
    display: flex;
    flex-wrap: wrap;
    width: 50px;
  }

  &__colors-list {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    width: 100%;
  }

  &__colors-item {
    box-sizing: border-box;
    border: 2px solid transparent;
    margin-top: 10px;
    margin-right: 10px;
    transition: 0.7s;
    &--active {
      box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.2);
      border: 2px solid var(--main-green);
    }
  }

  &__colors-example {
    cursor: pointer;
    display: flex;
    width: 25px;
    height: 25px;
  }
}