.course-section {
  display: flex;
  padding: 30px 0;
  background-color: var(--main-white);

  &__wrapper {
    padding: 0 20px;
    box-sizing: border-box;
    max-width: 1900px;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin: 0 auto;
    width: 100%;
  }
  &__info {
    display: flex;
    flex: 1;
    margin-left: 40px;
    flex-direction: column;
  };
}

@media screen and (max-width: 650px) {
  .course-section__info {
    margin-left: 0;
  }
}