@media screen and (max-width: 950px) {
  .buy-course {
    &__header {
      padding: 40px 0 27px 0;
      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 150px;
        height: 230px;
        background-size: cover;
        background-image: url("../../../../assets/img/knight.svg");
        z-index: -1;
        background-repeat: no-repeat;
      }
    }

    &__header-title {
      font-size: 60px;
      line-height: 72px;
    }

    &__header-container {
      display: flex;
    }

    &__header-info-container {
      width: 100%;
    }

    &__header-preview-button {
      padding: 12px 17px 12px 50px;
      border-radius: 8px;
      position: relative;
      border: none;
      transition: 0.5s;
      box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
      &::before {
        position: absolute;
        transition: 0.5s;
        content: '';
        left: 17px;
        top: 50%;
        transform: translateY(-50%);
        background-image: url("../../../../assets/img/play_black.svg");
        width: 17px;
        height: 14px;
        background-repeat: no-repeat;
        background-position: center;
      }

      &:hover {
        background-color: white;
        color: black;
        padding: 12px 17px 12px 50px;
        border: none;
        box-shadow: 0 3px 12px rgba(0, 0, 0, 0.2);
        &::before {
          background-image: url("../../../../assets/img/play_black.svg");
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .buy-course {
    &__header {
      &::before {
        content: "";
        display: none;
      }
    }


    &__header-title {
      font-size: 52px;
      line-height: 60px;
    }

    &__header-info-item {
      font-size: 12px;
    }

    &__header-buttons-container {
      flex-direction: column;
    }

    &__button {
      margin-right: 0;
      margin-bottom: 15px;
    }
  }
}

@media screen and (max-width: 600px) {
  .buy-course {
    &__header-preview-button {
      flex-grow: 1;
      &::before {

        left: 25%;
      }
    }
  }
}