@import '../../../../style/variables.scss';

.container {
  border-bottom: 1px solid transparent;
  padding: 0;
  margin-bottom: 0;
  height: auto;
  max-height: 0;
  transition: $transition;
  overflow: hidden;
  background-color: #7FCD87;
  &.show{
    padding: 16px 0 20px;
    margin-bottom: 25px;
    max-height: 150px;

  }
};

.title {
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  line-height: 27px;
  margin-left: 120px;
  span {
    color: #fff;
    font-size: 22px;
    font-weight: 700;
    line-height: 27px;
    cursor: pointer;
    text-decoration: underline;
  }
  &__wrapper {
    width: 100%;
    max-width: 1200px;
    padding: 0 20px;
    margin: 0 auto;
  };
};

@media screen and (max-width: 650px) {
  .container {
    border-bottom: none;
    padding: 18px 0 0;
    margin-bottom: 15px;
  }
  .title {
    font-size: 16px;
    line-height: 20px;
    span {
        
      font-size: 16px;
      line-height: 20px;
    }
    &__wrapper {
      padding: 0 15px;
    };
  };
}

@media (max-width: 541px) {
  .container {
    padding: 0;
    margin-bottom: 0;
  }
}