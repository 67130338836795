.subscription {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  padding: 50px 0;

  &__info {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
  }

  &__block {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex-grow: 1;
    align-items: center;
    max-width: 460px;
    min-height: 540px;
    margin: 0 30px;
    box-sizing: border-box;
    background-color: #F0F0F0;
    border-radius: 14px;
    background-image: url("../../../assets/img/knight_back.svg");
    background-repeat: no-repeat;
    background-position: bottom left;
    background-size: 32%;
    transition: 0.7s;
    &:hover {
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
    }

    &:last-of-type {
      background-image: url("../../../assets/img/king_pawn_back.svg");
      background-position: bottom right;
      background-size: 58%;
    }

    &--active {
      background-color: #DDEBEC;
      background-image: url("../../../assets/img/knight_back_white.svg");

      &:last-of-type {
        background-image: url("../../../assets/img/king_pawn_back_white.svg");
      }
    }
  }

  &__container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    margin-top: 70px;
    justify-content: center;
    padding: 0 20px;
  }

  &__title {
    font-size: 52px;
    width: 250px;
    margin-top: 20px;
    text-align: center;
    font-weight: 700;
  }

  &__text {
    width: 350px;
    text-align: center;
    font-size: 18px;
  }

  &__item-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    flex-grow: 1;
    background: linear-gradient(45deg, rgba(220,220,220,0.5) 0%, rgba(255,255,255,1) 100%);
  }

  &__options {
    display: flex;
    justify-content: center;
    margin-top: 18px;
    padding: 0 10px;

    &--column {
      flex-direction: column;
      margin-bottom: 15px;
    }
  }

  &__options--marked &__options-item {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      background-image: url("../../../assets/img/check-black.svg");
      background-position: center;
      background-size: contain;
      left: -8px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__options-item {
    font-size: 18px;
    padding: 2px 10px;
    &--small {
      max-width: 400px;
      text-align: center;
    }
  }

  &__additional {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__additional-message {
    font-size: 18px;
    color: var(--main-white);
    font-weight: 700;
    margin: 30px;
    max-width: 980px;
    flex-grow: 1;
    text-align: center;
    padding: 12px 20px;
    box-sizing: border-box;
    background-color: var(--main-green);
    border-radius: 15px;
  }

  &__additional-text {
    display: flex;
    margin: 0 auto;
    max-width: 980px;
    text-align: center;
    font-size: 18px;
  }

  &__type {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .subscription &__button {
    margin-top: auto;
    margin-bottom: 30px;
    width: 75%;
    padding: 8px 15px;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    color: var(--main-white);

    &--red {
      background-color: var(--red);
    }
  }

  &__rate-container {
    margin-bottom: 25px;
  }

  &__rate {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    text-align: center;
  }

  &__additional-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 980px;
    padding: 12px 20px;
  }

  &__additional-item {
    max-width: 460px;
    box-sizing: border-box;
    position: relative;
    font-size: 14px;
    line-height: 20px;
    padding-left: 20px;
    margin-right: 60px;
    margin-top: 15px;

    & a {
      text-decoration: underline;
    }
    &:nth-child(2n) {
      margin-right: 0;
    }
    &::before {
      content: '';
      position: absolute;
      width: 15px;
      height: 15px;
      left: 0;
      top: 4px;
      background-color: var(--main-green);
      border-radius: 50%;
    }
  }
}

.subscription__block--active {
  .subscription {
    &__container {
      background-color: var(--main-green);
      margin-top: 0;
      color: var(--main-white);
      border-bottom: 2px dotted var(--main-black);
    }

    &__text {
      margin-top: 30px;
    }
  }
}