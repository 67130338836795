@media screen and (max-width: 750px) {
  .subscription {
    &__list {
      width: 90%;
    }

    &__list-item {
      width: 100%;
      padding: 10px 15px;
    }

    &__list-container {
    }

    &__item-text {
      font-size: 1rem;
    }
  }
}