.subscription-popup {
  //position: fixed;
  //top: 0;
  //right: 0;
  //bottom: 0;
  //left: 0;
  //z-index: 2000;
  //overflow: auto;
  //align-items: center;
  //justify-content: center;
  //background-color: rgba(0, 0, 0, 0.3);

  &__container {
    padding: 80px 0;
    display: flex;
    justify-content: center;
  }

  &__content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 340px;
    padding: 15px 20px;
    border-radius: 6px;
    background-color: white;
    box-sizing: border-box;
    animation: onShow 0.4s;
  }

  &__title {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    text-align: center;
    margin: 5px 0;
  }

  &__text {
    text-align: center;
    line-height: 22px;
    margin: 5px 0;
  }

  &__button {
    font-size: 14px;
    cursor: pointer;
    margin: 8px 0;
    padding: 10px 14px;
    border-radius: 5px;
    text-transform: uppercase;
    background-color: var(--main-green);
    color: var(--text-white) !important;
    &:hover {
      background-color: #34928a;
    }
  }

  &__work-in-progress {
    color: darkred;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid gray;
    margin-bottom: 10px;
    padding: 5px 0 10px 0;
  }
}

@keyframes onShow {
  0% {
    transform: scale(0.3);
  }

  100% {
    transform: scale(1);
  }
}