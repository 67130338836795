.container {
  background: #fff;
  border-radius: 13px;
  width: 560px;
  overflow: hidden;
};

.add {
  &__navigation {
    border-radius: 13px 13px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    color: #3C3B37;
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
    border-bottom: 2px dashed #000000;
    svg:hover {
      cursor: pointer;
      path {
        fill: #504e4e;
      };
    };
  };
  &__superCategories {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    background: rgba(155, 226, 220, 0.46);
    padding: 15px 0 15px 40px;
  };
  &__buttonsWrapper {
    padding: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__button {
      font-size: 18px;
      max-width: 230px;
      flex: 1;
      color: #fff;
      height: 52px;
      border-radius: 12px;
      font-weight: 700;
      line-height: 22px;
      &:first-of-type {
        margin-right: 10px;
      };
      &_grey {
        background: #CCC;
        &:hover {
          background: #acaaaa;
        };
      };
      &_green {
        background: #74b7bb;
        &:hover {
          background: #73dade;
        };
      };
    };
  };
};

.accordion, .coursesAccordion {
  width: 100%;
  max-height: 48px;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  &__title, &__item {
    flex: 1;
    display: flex;
    max-height: 48px;
    min-height: 48px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #F0F0F0;
    height: 48px;
    transition: background .3s linear;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
    & > div {
      display: flex;
    };
    &:hover {
      background: #f0f0f0;
    };
  };
  &__title {
    & > svg {
      transform: rotate(90deg);
      transition: transform .3s linear;
    };
  };
};

.coursesAccordion {
  .textBold {
    padding: 10px 20px 10px 40px !important;
    font-weight: 700;
  };
  &__item {
    padding: 10px 20px 10px 10px;
  };
  &__title {
    padding: 10px 20px 10px 10px;
  };
  &__checkbox {
    position: relative;
    width: 16px;
    border-radius: 2px;
    border: 2px solid #74B7BB;
    transition: background-color .3s linear;
    cursor: pointer;
    height: 16px;
    margin-right: 12px;
  };
  .active {
    border: none !important;
    background-color: #74B7BB;
    background-repeat: no-repeat;
    background-position: 60% 50%;
    background-image: url("../../../../../../assets/img/partners/check.svg");
  };
};

.accordion {
  &__title {
    padding: 10px 20px 10px 40px;
    font-weight: 700;
  };
  &__item {
    padding: 10px 20px 10px 55px;
  };
};

.isOpen {
  animation: openAccordion 2.5s ease-in;
  animation-fill-mode: forwards;
  & > button {
    & > svg {
      transform: rotate(-90deg) !important;
    };
  };
};

.isClose {
  animation: closeAccordion .5s cubic-bezier(.23, .82, .39, .93);
  animation-fill-mode: forwards;
};

@keyframes openAccordion {
  0% {
    max-height: 48px;
  }
  20% {
    max-height: 800px;
  }
  100% {
    max-height: 10000px;
  }
}

@keyframes closeAccordion {
  0% {
    max-height: 10000px;
  }
  80% {
    max-height: 800px;
  }
  100% {
    max-height: 48px;
  }
}