.chess-display {
  height: 100%;
  display: flex;
  position: relative;
  min-height: 583px;

  &_webinar {
    width: 100%;
    min-height: auto !important;
    & > div {
      padding: 0 !important;
    }
    &__message {
      position: absolute;
      color: #fff;
      padding: 8px 25px;
      top: 5px;
      z-index: 100;
      opacity: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      white-space: nowrap;
      animation: showMessage 5s linear;
      animation-fill-mode: forwards;
      right: 5px;
      font-weight: 400;
      font-size: 20px;
      letter-spacing: -7.5%;
      line-height: 24px;
      border-radius: 6px;
      &_correct {
        background: #60bb51;
      }
      p {
        color: inherit;
        font-weight: 700;
        line-height: inherit;
        font-size: inherit;
        margin-top: 3px;
      }
      &_incorrect {
        background: #7f170e;
      }
    }
  }

  &__container {
    display: flex;
    flex-grow: 1;
    padding: 0;
    max-width: 100%;

    &--buttons {
      flex-direction: column;
      //padding: 20px 50px 19px 50px;
    }
    &_webinar {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__statistic {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: space-between;
    padding: 0 10px;

    &-turn {
      flex: 0 0 50%;
    }

    @media (max-width: 541px) {
      flex-direction: row;
    }
  }

  &__turn-container {
    display: flex;
    margin: 25px 0 18px 20px;
    align-items: center;

    @media (max-width: 541px) {
      margin: 0;
      padding: 20px 0 20px 10px;
    }
  }

  &__difficulty {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-content: center;
  }

  &__wrapper {
    display: flex;
    flex-grow: 1;
    margin-left: 20px;
    &_webinar {
      margin-left: 0 !important;
    }
    //height: 500px;
    &--loading {
      height: 400px;
      align-items: center;
    }
  }

  &__table {
    width: 60%;
    color: var(--text-white);
    font-weight: bold;
    text-align: center;

    &--buttons {
      background-position: left;
    }
  }

  &__description {
    flex: 1 1 auto;
    //position: relative;

    &_webinar {
      justify-content: flex-start !important;
      //padding: 25px 25px 15px 0;
      flex: 0 0 47%;
      overflow: hidden;
    }

    &--repeat {
      display: flex;
      flex-direction: column;
      //justify-content: space-between;
    }
  }

  &__chessboard {
    flex: 0 1 auto;
    position: relative;
    font-weight: bolder;
  }

  &__description-overlay {
    background-color: transparent;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }

  &__title {
    font-weight: bold;
    font-size: 17px;
    line-height: 21px;
    color: #3c3b37;
    border-bottom: 1px solid var(--course-border);
    text-align: center;
    margin-bottom: 26px !important;
    padding: 8px 0;
    &_webinar {
      //margin-top: 30px;
    }
  }

  &__name {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 15px;
    margin-left: 25px;
  }

  &__chess-img {
    margin-right: 16px;
  }

  &__step {
    display: flex;
    font-size: 14px;
    line-height: 17px;
    color: #9c9c9c;
    user-select: none;
    -webkit-user-select: none;
  }

  &__dotes {
    margin: 15px 0;
    display: block;
    flex-grow: 1;
    border-top: 2px dotted var(--course-border);
    &_webinar {
      flex-grow: 0;
    }
    &--mobile {
      display: none;
    }
  }

  &__hint {
    display: flex;
    border-radius: 50%;
    background-color: #f7ce43;
    position: absolute;
    bottom: 80px !important;
    right: 20px;
    cursor: pointer;

    &:hover {
      background-color: var(--main-green);
    }

    &:disabled {
      background-color: #898989;
    }
  }

  &__man {
    display: inline-block;
  }

  &__step-container {
    display: flex;
    align-items: center;
    margin-top: 25px;
    &_repeat {
      margin-bottom: 18px;
      & > div {
        display: flex;
      }
    }
  }

  &__chess-side {
    padding: 19.5px;
    border-radius: 50%;
    margin-right: 5px;
    background-color: rgba(0, 0, 0, 0.2);
  }

  &__radio-button-step {
    display: none;
  }

  &__radio-button-step:checked + label {
    background-color: #75b6ba;
  }

  &__side {
    color: #9c9c9c;
    font-size: 16px;
    margin-bottom: 8px;
  }

  &__control-title {
    color: #b2b3b3;
    margin-bottom: 15px;
    font-size: 16px;
  }

  &__start-button {
    cursor: pointer;
    background-color: rgb(250, 100, 100);
    padding: 12px 40px;
    max-width: 200px;
    font-size: 18px;
    color: var(--text-white);
    border-radius: 10px;

    &--active {
      box-shadow: 0 0 0 2px white, 0 0 0 4px rgb(250, 100, 100);
      background-color: rgb(250, 50, 50);
    }

    &:hover {
      background-color: #e31e24;
    }
  }
  &__control-container {
    margin: 0 20px;
    transition: .4s ease;
  }

  &__control-button {
    cursor: pointer;
    background-color: #75b6ba;
    padding: 19px;
    border-radius: 50%;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-position: center;
    box-sizing: border-box;
    &:hover {
      background-color: #34928a;
    }

    &:disabled {
      cursor: initial;
      background-color: #898989;
    }

    &--active {
      box-shadow: 0 0 0 2px white, 0 0 0 4px #75b6ba;
    }
  }

  &__control-button--play {
    background-size: 80%;
    background-image: url("../../../../../assets/img/play.png");
  }

  &__control-button--back {
    background-size: 25%;
    background-image: url("../../../../../assets/img/left.png");
  }

  &__control-button--repeat {
    background-size: 80%;
    background-image: url("../../../../../assets/img/repeat.png");
  }

  &__control-button--forward {
    background-size: 25%;
    background-image: url("../../../../../assets/img/right.png");
  }

  &__picture {
    min-width: 50%;
    max-width: 50%;
    color: var(--text-black);
    background-position: center;
    border-right: 1px solid var(--course-border);
    border-top: 1px solid var(--course-border);
    border-top-right-radius: 7.5px;
    border-bottom-right-radius: 7.5px;
    background-repeat: no-repeat;
    //margin:0 20px;
    background-size: contain;
    min-height: 330px;
    &_webinar {
      box-sizing: border-box;
      margin-left: 20px;
      flex: 0 0 50%;
      max-width: initial !important;
      min-width: initial !important;
    }
  }

  &__check-button {
    background-color: var(--main-green);
    border-radius: 15px;
    color: var(--text-white);
    font-weight: bold;
    padding: 15px 0;
    font-size: 16px;
    margin: 10px 20px 0;

    &:hover {
      background-color: #34928a;
    }
  }

  &__buttons-container {
    display: flex;
    justify-content: center;
    padding: 14px 20px;

    &--mobile {
      display: none;
    }
  }

  &__popup-container {
    display: flex;
    flex-direction: column;
    min-width: 300px;
    background: rgb(173, 225, 225);
    background: linear-gradient(180deg, rgba(173, 225, 225, 1) 0%, rgba(255, 255, 255, 1) 100%);
    padding: 28px 18px;
    border-radius: 8px;
    animation: onPopupDisplay 0.3s;
  }

  &__popup-rating {
    margin: 5px 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;

    &__container {
      display: flex;
      justify-content: center;
      gap: 14px;
      width: 100%;
      height: 40px;
      align-items: center;
    }

    &__title {
      font-weight: 800;
      font-size: 16px;
      min-width: fit-content;
    }

    &__delta-rating {
      background: #74B7BB;
      height: 100%;
      width: 100%;
      max-width: 50px;
      min-width: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 16px;
      font-weight: bold;
      border-radius: 16px;
    }

    &__current-rating {
      font-size: 16px;
    }
  }

  &__popup-list {
    background: #E9FEFC;
    padding: 12px;
    border-radius: 12px;
    width: 100%;
  }

  &__circle {
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: white;
    margin-right: 10px;

    &--blue {
      background-color: rgb(114, 188, 255);
    }

    &--yellow {
      background-color: rgb(255, 225, 76);
    }

    &--red {
      background-color: rgb(251, 103, 92);
    }

    &--orange {
      background-color: orange;
    }

    &--green {
      background-color: rgb(118, 233, 132);
    }
  }

  &__popup-item {
    display: flex;
    align-items: center;
    margin: 12px 0;
    justify-content: space-between;
    width: 100%;
    height: 30px;
  }

  &__popup-text {
    flex-grow: 1;
    font-size: 16px;
    padding: 0 4px;
    display: flex;
    justify-content: space-between;

    &__data {
      font-weight: bold;
      font-size: 18px;
    }
  }

  &__popup-button {
    cursor: pointer;
    background-color: rgb(70, 184, 188);
    text-transform: uppercase;
    color: var(--text-white);
    border-radius: 2px;
    padding: 10px 15px;
    flex-grow: 1;
    font-weight: bold;

    &:hover {
      background-color: rgb(45, 159, 158);
    }
  }

  &__popup-image {
    height: 200px;
    flex-grow: 1;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("../../../../../assets/img/cup.png");
  }

  &__popup-title {
    font-size: 16px;
    text-align: center;
    margin: 10px 0;
  }

  &__achievement {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin-bottom: 18px;

    &__title {
      font-size: 18px;
      font-weight: bold;
    }

    &__info {
      background: #F8C100;
      color: white;
      height: 48px;
      width: fit-content;
      padding: 0 16px;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      border-radius: 24px;
    }
  }
}

.chess-display--loading {
  .chess-display {
    &__picture {
      background: #f6f7f8;
      background: linear-gradient(to right, #fafafa 8%, #f4f4f4 38%, #fafafa 54%);
      background-size: 1000px 640px;
      animation: onLoading 1.5s infinite;
      position: relative;
      &::after {
        content: "Loading...";
        text-align: right;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-image: url("../../../../../assets/img/logo_single.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 50px;
        opacity: 0.2;
      }
    }

    &__title,
    &__name {
      height: 20px;
      width: 120px;
      background: rgba(109, 194, 187, 0.1)
        linear-gradient(to right, rgba(109, 194, 187, 0.1) 8%, #fafafa 38%, rgba(109, 194, 187, 0.1) 54%);
      background-size: 1000px 640px;
      animation: onLoading 1.5s infinite;
    }

    &__title {
      height: 24px;
      width: 200px;
    }

    &__description {
      max-height: none;
    }

    &__test-item {
      margin-bottom: 0;
      margin-top: 8px;
    }

    &__test-label {
      max-width: 100%;
      border-color: transparent;
      height: 15px;
      border-radius: 0;
      background: #f6f7f8;
      background: linear-gradient(to right, #fafafa 8%, #f4f4f4 38%, #fafafa 54%);
      background-size: 1000px 640px;
      animation: onLoading 1.5s infinite;
      &::before {
        content: "";
        display: none;
      }
    }
  }
}

.selectPopup {
  position: fixed;
  display: flex;
  padding: 50px;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.selectPopup {
  position: fixed;
  display: flex;
  padding: 50px;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.marginBottom {
  margin-bottom: 18px;
}

div[data-testid="white-square"],
div[data-testid="black-square"] {
  > div:first-child {
    user-select: none;

    > div:first-child:not([data-testid]),
    > div:last-child:not([data-testid]),
    div[data-testid="bottom-left-1"] {
      text-transform: lowercase;
      font-size: 14px !important;
    }

    div[data-testid="bottom-left-a"],
    div[data-testid="column-b"],
    div[data-testid="column-c"],
    div[data-testid="column-d"],
    div[data-testid="column-e"],
    div[data-testid="column-f"],
    div[data-testid="column-g"],
    div[data-testid="column-h"] {
      text-transform: lowercase;
      font-size: 14px !important;
      padding-right: 8px !important;
    }
  }
}

@keyframes onPopupDisplay {
  from {
    transform: scale(0.3);
  }

  to {
    transform: scale(1);
  }
}

@keyframes showMessage {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  85% {
    opacity: 1;
  }
  90% {
    opacity: 0;
    width: auto;
    padding: 8px 25px;
  }
  100% {
    opacity: 0;
    width: 1px;
    padding: 0;
    overflow: hidden;
  }
}

@media screen and (max-width: 900px) {
  .chess-display {
    &__wrapper {
      margin-left: 0;
    }
    &__picture {
      border-right: none;
    }
    &_webinar {
      &__message {
        font-size: 14px;
        line-height: 20px;
        top: 0;
        right: 5px;
        padding: 8px 25px;
        max-width: calc(100vw - 20px);
      }
    }
    &__control-container {
      margin-left: 0;
      &_block {
        display: block !important;
        padding: 0 15px;
      }
    }
  }
}

@media screen and (max-width: 541px) {
  .chess-display {
    &__container {
      min-height: 100%;
    }
    &__test-list {
      max-height: none !important;
    }
    &__name {
      margin-bottom: 10px !important;
    }
  }
}
.w50 {
  width: 50%;
}

.hide {
  @media (min-height: 542px) {
    height: auto;
    max-height: 0;
    overflow: hidden;
  }

  @media (max-width: 541px) {
    width: auto;
    max-width: 0;
    overflow: hidden;
  }
}
