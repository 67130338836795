.footer {
  border-top: 1px solid #9D9E9E;
  display: flex;
  background-color: var(--main-white);

  &__container {
    max-width: 1200px;
    flex-grow: 1;
    padding: 15px;
    margin: 0 auto;
    box-sizing: border-box;
  }

  &__list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }

  &__item {
    margin: 0 30px;
  }

  &__link {
    cursor: pointer;
    font-weight: bold;
  }

  & .arrow-bottom {
    margin-left: 5px;
  }

  &__sub-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
  }
}

.footer__bonus {
  transition: transform .5s ease-in-out;
  &:hover {
    transform: rotate(360deg);
  }
}

@keyframes bonus {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}