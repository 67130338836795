.buy-course {

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 70px;
  }

  &__header-container,
  &__content-container {
    max-width: 1200px;
    box-sizing: border-box;
    padding: 8px 20px;
    width: 100%;
    color: var(--main-white);
  }

  &__content-container {
    color: var(--main-black);
    display: flex;
    margin-top: 30px;
  }

  &__content-main {
    width: 67%;
  }

  &__content-bar {
    width: 28%;
    margin-left: auto;
  }

  &__main-block {
    margin-bottom: 60px;
    &--flex {
      display: flex;
    }
  }

  &__author-container {
    display: flex;
    align-items: flex-start;
  }

  &__author-image {
    display: block;
    border-radius: 50%;
    min-width: 75px;
    max-width: 75px;
    height: 75px;
    object-fit: cover;
    object-position: center;
  }

  &__author-info {
    margin-left: 15px;
  }

  &__author-name {
    font-size: 18px;
    margin: 0 0 8px 0;
  }

  &__author-text {
    font-size: 16px;
    line-height: 22px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  & .section-grid__item {
    margin-right: 18px;
    margin-top: 5px;
    max-width: 240px;
    min-width: 240px;

    //&:last-of-type {
    //  margin-right: 0;
    //}
  }

  &__text {
    font-size: 16px;
    line-height: 22px;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 22px;
    line-height: 26px;
  }

  &__board-image {
    width: 200px;
    height: 200px;
    flex-shrink: 0;
    background-image: url("../../../assets/img/board-image.png");
    background-position: center;
    background-size: contain;
    object-fit: cover;
    object-position: center;
    margin-right: 20px;
  }

  &__task-list {
    border-radius: 8px;
    max-height: 420px;
    overflow-y: auto;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    scrollbar-color: rgba(10,10,10,0.2) rgba(10,10,10,0.1);
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba(200,200,200,0.2);
      border-left: 1px solid var(--course-border);
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgb(200,200,200);
      border-left: 1px solid var(--course-border);
    }
  }

  &__content-bar-block {
    margin-bottom: 35px;
    &--gap {
      margin-top: 60px;
    }
  }

  &__share-button {
    display: flex;
    position: relative;
    width: 100%;
    border: 1px solid var(--main-green);
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    background-color: var(--main-white);
    color: var(--main-green);
    padding: 10px 70px 10px 40px;
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
    cursor: pointer;
    transition: 0.5s;
    &::before {
      position: absolute;
      content: '';
      background-image: url("../../../assets/img/share.svg");
      top: 50%;
      transform: translateY(-50%);
      right: 10%;
      width: 30px;
      height: 30px;
      background-position: center;
      background-repeat: no-repeat;
    }

    &:hover {
      box-shadow: 0 3px 15px var(--main-green-a);
    }
  }

  & .study-slider__card-image {
    height: 100%;
    max-width: 140px;
  }

  & .study-slider__card {
    margin: 0 0 20px 0;
  }

  & .study-slider__card-info {
    padding: 10px;
  }

  & .study-slider__card-title {
    font-size: 12px;
  }

  & .study-slider__card-count {
    font-size: 12px;
  }

  & .study-slider__card-name {
    font-size: 14px;
  }

  &__preview {
    display: flex;
    flex-direction: column;
    width: 70%;
    height: 100%;
    max-width: 700px;
    margin-top: 50px;
    position: relative;
  }

  &__preview-close {
    position: absolute;
    display: block;
    top: -50px;
    right: -50px;
    background-image: url('../../../assets/img/close-white.svg');
    width: 25px;
    height: 25px;
    padding: 25px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 18px;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      background-color: rgba(0,0,0,0.1);
    }
  }
}