@media screen and (max-width: 700px) {
  .buy-card {
    margin: 0 auto 20px auto;
    width: 100%;

    &__container {
      max-width: 760px;
      flex-grow: 1;
      box-shadow: none;
      border-radius: 0;
    }

    &__header {
      padding: 20px 15px;
      &::after {
        content: '';
        display: none
      }
    }

    &__info-wrapper {
      margin-left: 0;
    }

    &__title {
      font-size: 16px;
      line-height: 20px;
    }

    &__price {
      font-size: 16px;
      line-height: 20px;
    }

    &__block {
      padding: 15px;
    }

    &__list {
      display: flex;
      flex-direction: column;
    }

    &__list-item {
      width: 100%;
      margin-top: 15px;
      flex-grow: 1;
      & p {
        font-size: 14px;
        line-height: 18px;
      }
    }

    &__button {
      display: flex;
      font-size: 16px;
      box-sizing: border-box;
      cursor: pointer;
      justify-content: center;
      text-align: center;
      width: auto;
      flex-grow: 1;
      margin: 24px 15px 40px;
    }

    &__button-container {
      display: flex;
      flex-direction: column;
    }
  }
}