.buy-card {
  margin: 50px auto;
  display: flex;
  flex-direction: column;

  &__container {
    max-width: 760px;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    border-radius: 14px;
    overflow: hidden;
  }

  &__header {
    background-color: #DDEBEC;
    padding: 20px 25px;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px dotted;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 100px;
      background-image: url("../../../../assets/img/king_pawn_back_white.svg");
      background-size: 100px;
      background-position: bottom right;
      background-repeat: no-repeat;
    }
  }

  &__image {
    height: 152px;
  }

  &__info-wrapper {
    margin-right: auto;
    margin-left: 22px;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 16px;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
  }

  &__author {
    font-size: 14px;
    line-height: 17px;
    color: #414142;
  }

  &__price {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
  }

  &__block {
    padding: 25px;
  }

  &__text {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__list-item {
    display: flex;
    align-items: center;
    width: 50%;
    box-sizing: border-box;
    padding-right: 10px;
    margin-top: 20px;
    flex-grow: 1;
    & p {
      font-size: 16px;
      line-height: 20px;
    }
  }

  &__item-img {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    background-color: var(--main-green);
    border-radius: 8px;
    margin-right: 14px;
    background-position: center;
    background-size: 20px;
    background-repeat: no-repeat;
    &--platform {
      background-image: url("../../../../assets/img/buyCourseCard/platform.svg");
    }
    &--forever {
      background-image: url("../../../../assets/img/buyCourseCard/access.svg");
    }
    &--exercise {
      background-image: url("../../../../assets/img/buyCourseCard/exercies.svg");
    }
    &--video {
      background-image: url("../../../../assets/img/buyCourseCard/play.svg");
    }
  }

  &__button {
    display: flex;
    background-color: var(--red);
    color: var(--main-white);
    padding: 14px 20px 14px 20px;
    font-size: 18px;
    cursor: pointer;
    justify-content: center;
    margin-bottom: 40px;
    text-align: center;
    margin-left: auto;
    width: 50%;
    border-radius: 14px;
    transition: 0.5s;
    font-weight: bold;
    margin-top: 24px;

    &:hover {
      background-color: rgb(213, 92, 84);
      box-shadow: 0 0 15px rgb(213, 92, 84);
    }
  }
}