.radio-control {
  &__container {
    display: inline-block;
    width: 52px;
    height: 32px;
    position: relative;
    background-color: rgba(120, 120, 128, 0.16);
    border-radius: 18px;
    cursor: pointer;
  }

  &__circle {
    position: absolute;
    display: inline-block;
    background-color: white;
    width: 27px;
    height: 27px;
    top: 2px;
    left: 3px;
    border-radius: 50%;
    box-shadow: 0 0 8px rgba(0,0,0,0.15);
    transition: 0.3s;
  }
}

.radio-control--active {
  .radio-control__container {
    background-color: var(--main-green);
  }

  .radio-control__circle {
    transition: 0.3s;
    left: calc(100% - 30px);
  }
}