.play-game {
  display: flex;
  flex-grow: 1;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    max-width: 1200px;
    margin: 0 auto;
    //padding: 40px;
  }

  &__type-container {
    display: flex;
    max-width: 1200px;
    width: 100%;
    box-sizing: border-box;
    padding: 20px 0;
  }

  &__type {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 70px 20px;
    box-sizing: border-box;
    margin: 5px 20px;
    font-size: 1.5rem;
    background-color: rgba(0,0,0,0.1);
    width: 50%;
    transition: 0.3s;
    font-weight: bold;
    text-shadow: 0 0 30px white;
    border-radius: 5px;
    text-align: center;
    &:hover {
      color: white;
      background-color: var(--main-green);
    }
    &--active {
      color: white;
      background-color: var(--main-green);
      box-shadow: 0 0 20px var(--main-green-a);
      background-image: url("../../../assets/img/logo_single.png");
      background-position: 10px 95%;
      background-repeat: no-repeat;
      background-size: 30px;
    }
  }
}

.play-game {
  & .chess-display__description {
    padding: 10px 0;
    text-align: center;
  }

  & .chess-display__control-button--forward {
    margin-right: 0;
  }

  & .chess-display__step {
    justify-content: center;
    margin: 10px 0;
  }
}

.free-position {
  width: 100%;
  display: flex;
  flex-grow: 1;
  border-left: 1px solid rgba(0, 0, 0, 0.5);
  border-right: 1px solid rgba(0, 0, 0, 0.5);

  &__step-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  &__step-title {
    font-size: 16px;
    margin-bottom: 10px;
  }

  &__step {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    padding: 25px 20px;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    flex-grow: 1;
    border-radius: 5px;

    &--black {
      background-image: url("../../../assets/img/pieces/bP.svg");
    }

    &--white {
      background-image: url("../../../assets/img/pieces/wP.svg");
    }

    &--active {
      background-color: var(--main-green);
    }
  }

  &__block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    width: 100%;
  }

  & .chess-display__chessboard {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }

  &__buttons-container {
    align-self: flex-start;
    margin-right: 30px;
    padding: 20px;
    display: flex;
    box-sizing: border-box;
    height: 100%;
    flex-direction: column;
    border-right: 1px solid rgba(0, 0, 0, 0.5);
  }

  &__fen {
    padding: 10px;
    box-sizing: border-box;
    background-color: rgba(0,0,0,0.2);
  }

  &__button {
    cursor: pointer;
    background: white;
    padding: 8px 20px;
    width: 215px;
    font-size: 16px;
    margin-top: 20px;
    border: 2px solid var(--main-green);
    color: var(--main-green);
    &:hover {
      color: var(--text-white);
      background-color: var(--main-green);
    }
    &--start {
      margin-top: auto;
      background: var(--main-green);
      color: var(--text-white);
      &:hover {
        color: var(--main-green);
        background-color: white;
      }
    }
  }
}

.play-game {
  & .chess-display__chessboard {
    margin-top: 30px;
  }
}
