.my-select {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  background-color: var(--main-white);
  font-weight: bold;
  font-size: 14px;
  word-wrap: normal;
  position: relative;
  margin-top: 0;
  border-radius: 0;
  outline: 0;

  &--warn {
    border-color: #bf0a0a !important;
    background-color: rgba(160, 0, 0, 0.1) !important;
    .my-select__arrow-container {
      background-color: inherit !important;
    }
  }

  &:hover,
  &:focus {
    outline: none;
  }

  &:hover &__options-list {
    outline: none;
  }

  &__title {
    margin: 0;
    padding: 8px 20px 8px 12px;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: none;
    outline: 0;
  }

  &__options-list {
    background-color: white;
    position: absolute;
    z-index: 1;
    top: calc(100% + 12px);
    left: -1px;
    right: -1px;
    box-shadow: 0 0 10px #959BAA;
    margin: 0;
    padding: 0;
    list-style: none;
    box-sizing: border-box;
    max-height: 200px;
    overflow-y: auto;
  }

  &__options-item {
    padding: 8px 8px;
    font-size: 12px;
    text-transform: none;

    &:hover {
      background-color: rgba(109, 194, 187, 0.2);
    }
  }

  &__header {
    position: relative;
    outline: 0;
  }

  &__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-1px);
    border: 5px solid transparent;
    border-top: 5px solid var(--main-black);
  }

  &__arrow-container {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    bottom: 2px;
    width: 30px;
    background-color: var(--main-white);
    position: absolute;
    right: 0;
    border-radius: 10px;

    &--active {
      top: 8px;
      animation: onOpen 0.3s;
      transform: translateY(-2px) rotate(180deg);
    }
  }

  &__close:hover {
    fill: #000000;
  }

  &__options-checkbox {
    display: inline-block;
    width: 10px;
    height: 10px;
    border: 1px solid black;
    margin-right: 8px;
    &--active {
      background-color: yellow;
    }

  }
}

.course-arrow{
  width: 16px !important;
  height: 16px !important;
  border-width: 2px !important;
}

.course-style{
  border-bottom: 1px solid #f0f0f0 !important;
}

@keyframes onOpen {
  from {
    transform: rotate(0);
  }
  to {
    transform: translateY(-2px) rotate(180deg);
  }
}