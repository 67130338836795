@media screen and (max-width: 800px) {
  .test {
    border-right: 0;
    border-left: 0;

    &__list {
      max-height: none;
      overflow-y: auto;
    }
  }
}