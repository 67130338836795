@import '../../../style/variables.scss';

.course-interface {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  background-color: var(--main-white);

  &__header-container {
    max-width: 1200px;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    margin: 0 auto;
    width: 100%;
    height: auto;
    max-height: 0;
    justify-content: space-between;
    align-items: center;
    transition: $transition;
    overflow: hidden;

    &.show-header{
      padding: 10px 20px;
      max-height: 100px;
    }
  }

  &__header-title {
    margin-top: 5px;
    font-size: 20px;
  }

  &__header-name {
    font-size: 14px;
  }

  &__header-button {
    cursor: pointer;
    border: 1px solid #B2B3B3;
    padding: 8px 28px;
    border-radius: 12px;
    transition: $transition;
    transition: .4s ease;

    &:hover {
      background-color: var(--main-green);
      border-color: var(--main-green);
      color: var(--text-white);
    }
  }
}

.interface {
  display: flex;
  flex-grow: 1;
  max-width: 2000px;
  width: 100%;
  position: relative;
  border-top: 1px solid var(--course-border);

  &__fullsize{
    position: absolute;
    z-index: 10;
    right: 30px;
    top: 5px;
  }

  &__hidesidebar{
    position: absolute;
    z-index: 10;
    right: 5px;
    top: 5px;
  }

  &__tasks-wrapper{
    width: auto;
    max-width: 0;
    transition: $transition;
    overflow: hidden;

    @media (max-width: 541px) {
      display: none;
    }
  }

  &__hide-button,
  &__full-button {
    cursor: pointer;
    width: 20px;
    height: 20px;
    background-color: var(--main-white);
    border: 1px solid var(--course-border);
    position: absolute;
    z-index: 10;
    right: 5px;
    top: 4px;
    background-image: url("../../../assets/img/arrow_right.svg");
    background-position: center;
    background-size: 20px;
    background-repeat: no-repeat;

    &:hover {
      box-shadow: 0 0 5px rgba(100,100,100,0.3);
    }

    &--hidden {
      transform: rotate(180deg);
    }
  }

  &__full-button {
    right: 30px;
    background-image: url("../../../assets/img/fullscreen.svg");
    background-size: 12px;
  }

  &__task {
    position: relative;
    flex-grow: 1;
    border-left: 1px solid var(--course-border);
  }

  &__tasks-container {
    width: 100%;
  }
}

.login__dotes--task {
  flex-grow: 0;
}

.show-tasks{
  max-width: 350px;
  min-width: 350px;
}

.registration{
  background: #7FCD87;
  max-width: 100%;

  .course-interface{
    &__header{
      color: #fff;

      &-button{
        color: #fff;
        border-color: #fff;

        &:hover{
          background: hsl(126, 44%, 45%);
        }
      }
    }
  }
}