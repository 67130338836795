.loader {
  width: 150px;
  height: 100px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  &__item {
    display: inline-block;
    padding: 2px 10px;
    margin: 5px;
    &:nth-child(1){
      animation: bounce 1s ease-in-out infinite;
    }

    &:nth-child(2){
      animation: bounce 1s ease-in-out 0.33s infinite;
    }

    &:nth-child(3){
      animation: bounce 1s ease-in-out 0.66s infinite;
    }
  }
}



@keyframes bounce {
  0%, 75%, 100%{
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }

  25%{
    -webkit-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -o-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

.loader2 {
  width: 150px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
  text-align: center;

  &__item2 {
    display: inline-block;
    padding: 0 10px;
    margin: 0;
    height: 16px;
    background-size: auto 16px;
    animation: rotate 1s ease-in-out infinite;
  }
}

@keyframes rotate {
  0%{
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }

  100%{
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loader4 {
  width: 150px;
  height: 100px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;

  &__item {
    display: inline-block;
    padding: 2px 10px;
    margin: 5px;
    &:nth-child(1){
      animation: rotate 1s ease-in-out infinite;
    }

    &:nth-child(2){
      animation: rotate 1s ease-in-out 0.33s infinite;
    }

    &:nth-child(3){
      animation: rotate 1s ease-in-out 0.66s infinite;
    }
  }
}

.loader3 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 150px;
  border-radius: 5px;
  padding: 25px 15px;
  position: relative;
  margin: 20px auto 0;

  &__item3 {
    display: inline-block;
    padding: 5px 15px;
    width: 40px;
    height: 40px;
    margin: 5px;
    background-size: contain;
    background-color: transparent;
    animation: go 1.3s ease-in-out infinite reverse;
  }
}

@keyframes go {
  0% {
    transform: translate(45px);
  }

  25% {
    transform: translateY(15px);
  }

  50% {
    transform: translate(-45px);
  }

  75% {
    transform: translateY(-15px);
  }

  100% {
    transform: translate(45px);
  }
}

.loader5 {
  display: flex;
  align-items: center;
  margin: auto;
  position: relative;
  width: 80px;
  height: 100%;

  & div {
    position: absolute;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #73b7bae6;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  & div:nth-child(1) {
    left: 8px;
    animation: loader5-ellipsis1 0.6s infinite;
  }
  & div:nth-child(2) {
    left: 8px;
    animation: loader5-ellipsis2 0.6s infinite;
  }
  & div:nth-child(3) {
    left: 32px;
    animation: loader5-ellipsis2 0.6s infinite;
  }
  & div:nth-child(4) {
    left: 56px;
    animation: loader5-ellipsis3 0.6s infinite;
  }
}

@keyframes loader5-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes loader5-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes loader5-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

