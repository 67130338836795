.applications {
  position: relative;
  display: flex;

  &__list {
    position: absolute;
    top: -10px;
    right: 0;
    background-color: white;
    width: 150px;
    box-shadow: 0 0 15px rgba(10,10,10,0.2);
    transform: translateY(-100%);
    border-radius: 4px;
  }

  &__item {
    display: flex;
    flex-direction: column;
  }

  &__link {
    display: inline-block;
    padding: 10px 12px;
    &:hover {
      background-color: whitesmoke;
    }
  }

  &__arrow-container {
    display: flex;
    align-items: flex-end;
    padding: 2px 4px 1px 4px;
    &--active {
      animation: onOpenClick 0.2s;
      transform: rotate(-180deg) translateY(-3px);
    }
  }

  &__arrow {
    width: 12px;
    height: 12px;
    background-image: url("../../assets/img/arrow-down.svg");
    background-position: center;
    background-size: contain;
  }
}

@keyframes onOpenClick {
  0% {
    transform: rotate(0) translateY(0);
  }
  100% {
    transform: rotate(-180deg) translateY(-3px)
  }
}