.password-change__list {
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
  min-width: 300px;
  background-color: white;
  border-radius: 15px;
  box-sizing: border-box;
  position: relative;
  margin: 50px 5px ;
}

.password-change__item {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.password-change__label {
  display: flex;
  flex-direction: column;
  position: relative;
}

.password-change__input {
  background-color: transparent;
  padding-right: 30px !important;
}

.password-change__close-button {
  cursor: pointer;
  position: absolute;
  top: -15px;
  right: -15px;

  &:hover .password-change__close-svg-path {
    fill: #ffffff;
  }
}

.password-change__button {
  font-size: 14px !important;
}