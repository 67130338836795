.section-grid {
  //width: 76%;
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  &__title {
    display: inline-block;
    margin-bottom: 15px;
    margin-top: 0;
    border-bottom: 1px solid #34928a;
    flex-grow: 0;
  }
}

.banner {
  color: #fff;
  position: relative;
  width: 100%;
  height: 320px;
  margin-bottom: 45px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  &__img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  };
  &__loading {
    height: 100%;
    width: 100%;
    -webkit-backface-visibility: hidden;
    outline: 1px solid transparent;
    display: block;
    border: none;
    background-color: rgba(109, 194, 187, 0.15);
  };
  &-text {
    position: absolute;
    bottom: 20px;
    color: white;
    left: 50px;
    right: 10%;
    &__title {
      font-size: 24px;
      line-height: 29px;
      font-weight: 700;
    };
    &__description {
      max-width: 500px;
    };
  };
};

@media screen and (max-width: 650px) {
  .banner {
    margin-bottom: 10px;
    height: 85px;
  }
}

