.icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.achievement {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    width: 100%;
    height: 115px;

    &__image {
        width: 100px;
        height: 100px;
        cursor: pointer;
        margin: 0 auto;
        z-index: 10;
    }
}

.message {
    box-sizing: border-box;
    opacity: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 170px;
    width: 120%;
    bottom: -40px;
    left: -100%;
    right: -100%;
    margin: auto;
    z-index: 20;
    background-color: #70b8b9;
    color: white;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
    padding: 0 10px 10px;

    &__active {
        opacity: 1;
    }
}

.name {
    font-size: 13px;
}

.description {
    word-break: break-word;
}