.support {
  display: flex;
  flex-direction: column;

  &__list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
  }

  &__item {
    max-width: 360px;
    width: 33%;
    margin: 10px 20px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 5px;
    box-sizing: border-box;
  }

  &__button {
    margin-top: 20px;
    font-size: 1.1rem !important;
    padding: 10px 15px !important;
  }

  &__text {
    font-size: 1.2rem;
    font-weight: bold;
    padding: 10px 0;
  }

}

@media screen and (max-width: 650px) {
  .support {
    &__item {
      width: 100%;
      max-width: none;
    }
  }
}