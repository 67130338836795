.subscription-popup {
  &__content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    padding: 15px 20px;
    border-radius: 6px;
    background-color: white;
    box-sizing: border-box;
  }
}