.confirm {
  background-color: var(--main-white);
  padding: 35px;
  border-radius: 14px;

  &__title {
    font-weight: bold;
    font-size: 18px;
    color: var(--main-green);
    margin: 0;
    text-align: center;
  }

  &__message {
    font-size: 16px;
    margin: 12px 0 26px 0;
  }

  &__button {
    font-weight: 400;
    font-size: 16px;
    max-width: 215px;
    width: 100%;
    margin-right: 20px;

    &--cancel {
      background-color: var(--gray);
    }

    &:last-of-type {
      margin-right: 0;
      margin-left: 20px;
    }
  }

  &__buttons-container {
    display: flex;
    justify-content: space-between;
  }
}