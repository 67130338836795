.game-settings {
  background-color: white;
  max-width: 1200px;
  width: 100%;
  padding: 20px 35px 35px 35px;
  border-radius: 15px;
  box-sizing: border-box;
  margin: 5px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__image {
    margin: 10px 0 10px 0;
    width: 400px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
  }

  &__item {
    margin-bottom: 18px;
    padding: 8px 15px;
  }

  &__label {
    display: flex;
    align-items: center;
  }

  &__text {
    color: var(--main-green);
    font-weight: bolder;
    width: 200px;
    font-size: 18px;
    margin-right: 10px;
  }

  &__select-input {
    border-bottom: 1px solid #C4C4C4;
  }

  &__select-title {
    font-size: 18px;
    font-weight: normal;
  }

  &__select-item {
    font-size: 16px;
    font-weight: normal;
    padding: 12px 15px;
    &:hover {
      background-color: var(--main-green);
      color: var(--text-white);
    }
  }

  &__select-list {
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
    top: 0;
    &--difficulty {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    //max-height: 300px;
    //overflow-y: auto;
  }

  &__start-button {
    cursor: pointer;
    color: var(--text-white);
    background: var(--main-green);
    padding: 8px 20px;
    width: 215px;
    border-radius: 10px;
    font-size: 18px;
    margin-top: 10px;
    border: 2px solid var(--main-green);
    &:hover {
      color: var(--main-green);
      background-color: white;
    }
  }

  &__item--button {
    margin-bottom: 0;
    margin-top: 20px;
    text-align: center;
    border: 0;
  }
}

.chess-display__level {
  display: inline-block;
  padding: 8px 0;
  font-size: 14px;
  font-weight: bolder;
}