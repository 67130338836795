@media screen and (max-width: 650px) {
  .courses__progress-container {
    padding-right: 10px;
  }

  .progress {
    margin: 0;
    padding: 5px 25px 5px 10px;

    &__line {
      height: 12px;
    }

    &__width {
      height: 15px;
      border-radius: 0;
    }

    &__menu-container {
      position: absolute;
      top: 0;
      right: 0;
      min-width: 15px;
    }

    &__menu-item {
      width: 4px;
      height: 4px;
      margin: 0 1px;
    }

    &__percents {
      font-size: 10px;
    }
  }


}