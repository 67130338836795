.search-page {
  display: flex;
  padding: 30px 0;

  &__wrapper {
    padding: 0 20px;
    box-sizing: border-box;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin: 0 auto;
    width: 100%;
  }

  &__list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    box-sizing: border-box;
  }

  &__title {
    align-self: flex-start;
    margin: 0 0 20px 15px;
    border-bottom: 1px solid #34928a;
    flex-grow: 0;
  }

  &__list--categories {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 15px 5px 15px;
  }

  &__category-item {
    border: 2px solid var(--main-green);
    padding: 10px 15px;
    margin-right: 20px;
    margin-bottom: 10px;
    &:hover {
      background-color: var(--main-green);
      color: var(--text-white);
    }
  }
}