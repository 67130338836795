.main-slider {
  border-top: 3px solid var(--main-green);
  border-bottom: 3px solid var(--main-green);
  position: relative;
  //padding: 25px;

  & .carousel {
    box-sizing: border-box;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
    //max-width: 1200px;
    box-sizing: border-box;
    //padding: 0 20px 25px 20px;
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    background-image: url("../../../assets/img/slider_back.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;

    &--2 {
      background-image: url("../../../assets/img/slider_back-right.png");
      background-position: right;
    }

    &--3 {
      background-image: url("../../../assets/img/slider_back-center.png");
      background-position: center;
    }
  }

  &__item-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    max-width: 950px;
    padding: 40px 25px;
    box-sizing: border-box;
  }

  &__item--3 &__item-wrapper {
    max-width: 1200px;
  }

  &__item--2 &__info {
    order: 1;
  }

  &__image {
    display: block;
    cursor: pointer;
    border: none;
    width: 320px;
    height: 200px;
    background-color: rgba(109, 194, 187, 0.25);
    background-repeat: no-repeat;
    border-radius: var(--course-border-radius);
    box-shadow: -4px 0 10px rgba(30, 30, 30, 0.25);
    object-fit: cover;
    object-position: center;
  }

  &__info {
    display: flex;
    cursor: pointer;
    flex-direction: column;
    align-items: flex-start;
    max-width: 430px;
    min-width: 250px;
  }

  &__options {
    display: flex;
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 10px;
  }

  &__list {
    //width: 80%;
    background-color: white;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 28px;
    text-align: left;
    font-weight: bold;
  }

  &__text {
    font-size: 18px;
  }
}

.main-slider__item--loading {
  .main-slider {

    &__image {
      opacity: 0.4;
      background-image: url("../../../assets/img/logo_single.png");
      background-position: center;
      background-size: 30px;
      background: rgba(109, 194, 187, 0.25);
      background-size: 1000px 640px;
      background: linear-gradient(to right, rgba(109, 194, 187, 0.25) 8%, #f4f4f4 38%, rgba(109, 194, 187, 0.25) 54%);
      animation: onLoading 5s infinite;
    }

    &__title {
      opacity: 0.4;
      width: 230px;
      height: 28px;
      background: rgba(109, 194, 187, 0.25);
      background-size: 1000px 640px;
      background: linear-gradient(to right, rgba(109, 194, 187, 0.25) 8%, #f4f4f4 38%, rgba(109, 194, 187, 0.25) 54%);
      animation: onLoading 5s infinite;
    }

    &__text {
      opacity: 0.4;
      width: 150px;
      height: 22px;
      background: rgba(109, 194, 187, 0.25);
      background-size: 1000px 640px;
      background: linear-gradient(to right, rgba(109, 194, 187, 0.25) 8%, #f4f4f4 38%, rgba(109, 194, 187, 0.25) 54%);
      animation: onLoading 5s infinite;
    }
  }
}