.advertising {
  position: absolute;
  z-index: 5;
  top: 0;
  right: 0;
  min-width: calc(25% + 2px);
  max-width: calc(25% + 2px);

  &__close {
    cursor: pointer;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    z-index: 6;
    top: 10px;
    right: 10px;
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
    background-color: var(--main-white);
    background-image: url("../../../assets/img/close.svg");
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center;
    &:hover {
      background-image: url("../../../assets/img/close-black.svg");
    }
  }

  &__image {
    width: 100%;
  }
}

@media screen and (max-width: 800px) {
  .advertising {
    min-width: auto;
    max-width: none;
    left: 0;
    padding: 40px 20px;
    box-sizing: border-box;
    right: 0;
  }
}