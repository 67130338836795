.App {
  font-size: 14px;
  font-family: -apple-system, "Century Gothic", sans-serif;
  outline: none;
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  //min-width: 935px;
}

:root {
  --main-green: #74b7bb;
  --dark-green: #34928a;
  --text-white: #ffffff;
  --text-black: #000000;
  --sub-green: #1a655c;
  --sub-blue: #1c4b9b;
  --gray: #cecece;
  --red: #e97068;
  --main-black: #000000;
  --main-white: #ffffff;
  --course-border: #f0f0f0;
  --main-green-a: rgba(116, 183, 187, 0.5);
  --course-border-radius: 12px;
}

@media screen and (max-width: 650px) {
  .App {
    min-width: 350px;
  }
}

main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  position: relative;
}

footer {
  flex-shrink: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 14px;
  font-family: -apple-system, "Century Gothic", sans-serif;
}

p,
span {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-family: -apple-system, "Century Gothic", sans-serif;
}

button {
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  font-family: -apple-system, "Century Gothic", sans-serif;
  outline: 0;
}

input {
  outline: none;
  font-family: -apple-system, "Century Gothic", sans-serif;
  font-size: 14px;
}

input[type="radio"],
input[type="checkbox"] {
  display: none;
}

a {
  text-decoration: none;
  color: var(--text-black);
  &:visited {
    color: initial;
  }
}

body {
  /*&::-webkit-scrollbar {
      width: 14px;
      background: whitesmoke;
  }

  &::-webkit-scrollbar-thumb {
      background: #74B7BB;
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 7px;
      cursor: pointer;
      transition: .4s ease;
      
      &:hover{
          border: 3px solid rgba(0, 0, 0, 0); 
      }
  }*/
}

.scroll-hidden {
  overflow: hidden;
  touch-action: none;
  -ms-touch-action: none;
  position: fixed;
  height: 100vh;
}

.scroll-hidden-mobile {
  overflow-y: hidden;
  position: fixed;
  touch-action: none;
  -ms-touch-action: none;
  position: relative;
  height: 100%;
}

.vk__logo {
  width: 20px;
  height: 20px;
  background-image: url("assets/img/vk.svg");
  background-position: center;
  background-size: 20px;
  background-repeat: no-repeat;
}

.google__logo {
  width: 20px;
  height: 20px;
  background-image: url("assets/img/google.png");
  background-position: center;
  background-size: 20px;
  background-repeat: no-repeat;
}

.facebook__logo {
  width: 20px;
  height: 20px;
  background-image: url("assets/img/facebook.png");
  background-position: center;
  background-size: 20px;
  background-repeat: no-repeat;
}

.apple__logo {
  width: 20px;
  height: 20px;
  background-image: url("assets/img/apple.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.chess__logo {
  width: 20px;
  height: 20px;
  background-image: url("assets/img/chess.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.video__logo {
  display: inline-block;
  width: 28px;
  height: 28px;
  background-image: url("assets/img/video.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 28px;
  margin-right: 14px;
}

.test__logo,
.position__logo,
.move__logo,
.repeat-task__logo,
.test-chess__logo {
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 14px;
  flex-shrink: 0;
}

.test__logo {
  width: 24px;
  height: 30px;
  background-image: url("assets/img/test.svg");

  &--solved {
    background-image: url("assets/img/test_solved.svg");
  }
}

.test-chess__logo {
  width: 24px;
  height: 30px;
  background-image: url("assets/img/test_chess.svg");

  &--solved {
    background-image: url("assets/img/test_chess-solved.svg");
  }
}

.position__logo {
  width: 24px;
  height: 30px;
  background-image: url("assets/img/position.svg");
  &--solved {
    background-image: url("assets/img/position_solved.svg");
  }
}

.move__logo {
  width: 24px;
  height: 30px;
  background-image: url("assets/img/move.svg");
  &--solved {
    background-image: url("assets/img/move_solved.svg");
  }
}

.repeat-task__logo {
  width: 24px;
  height: 30px;
  background-image: url("assets/img/task_repeat.svg");
  &--solved {
    background-image: url("assets/img/task_repeat_solved.svg");
  }
}

.close__logo {
  width: 15px;
  height: 15px;
  background-image: url("assets/img/close.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 15px;
}

.search__logo {
  width: 20px;
  height: 20px;
  background-image: url("assets/img/search.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
}

.person__logo {
  width: 28px;
  height: 28px;
  background-image: url("assets/img/person.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 28px;
}

.arrow {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-top: 1px solid var(--main-black);
  border-right: 1px solid var(--main-black);
  cursor: pointer;
}

.arrow-top {
  transform: rotate(-45deg);
}
.arrow-right {
  transform: rotate(45deg);
}
.arrow-bottom {
  transform: rotate(135deg);
  margin-bottom: 2px;
}
.arrow-left {
  transform: rotate(-135deg);
}

.slider-button {
  display: block;
  cursor: pointer;
  padding: 16px;
  box-shadow: 0 0 8px rgba(30, 30, 30, 0.3);
  background-color: var(--main-white);
  border-radius: 50%;
  position: relative;
  flex-grow: 0;
  z-index: 100;
  transition: 0.3s;

  &::after {
    content: "";
    position: absolute;
    display: inline-block;
    width: 8px;
    height: 8px;
    top: 12px;
    left: 12px;
    border-top: 1px solid var(--main-green);
    border-right: 1px solid var(--main-green);
  }

  &--left {
    &::after {
      left: 14px;
      transform: rotate(-135deg);
    }
  }

  &--right {
    &::after {
      left: 10px;
      transform: rotate(45deg);
    }
  }

  &:hover {
    box-shadow: 0 0 12px rgba(30, 30, 30, 0.4);
  }
}

.slider-position-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 10px;
  &--mobile {
    display: none;
  }
}

.slider-position {
  display: inline-block;
  width: 28px;
  height: 5px;
  background-color: rgb(220, 220, 220);
  margin: 0 3px;
  border-radius: 2px;
  cursor: pointer;
  transition: 0.3s;

  &--active {
    background-color: var(--main-green);
  }

  &:hover {
    background-color: var(--dark-green);
  }
}

.logo {
  height: 24px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 24px;
  background-image: url("assets/img/logo_single.png");
}

.white-pawn__logo {
  display: flex;
  height: 40px;
  width: 30px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("assets/img/white_pawn.png");
}

.white-pawn2__logo {
  display: flex;
  height: 26px;
  width: 26px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  background-image: url("assets/img/white_pawn2.png");
}

.black-pawn__logo {
  display: flex;
  height: 26px;
  width: 26px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  background-image: url("assets/img/black_pawn.png");
}

.black-pawn__logo2 {
  display: flex;
  height: 40px;
  width: 30px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("assets/img/black_pawn.png");
}

.hint__logo {
  height: 50px;
  width: 50px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("assets/img/hint.png");
}

.man__logo {
  height: 50px;
  width: 25px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("assets/img/man.png");
}

.man-colorfull__logo {
  height: 50px;
  width: 25px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("assets/img/man_colorfull.png");
}

.carousel__dot--selected {
  span {
    background-color: var(--main-green);
  }
}

::selection {
  background: rgba(109, 194, 187, 0.5);
}

.Toastify__toast {
  font-family: -apple-system, "Century Gothic", sans-serif !important;
}

@media screen and (max-width: 650px) {
  .slider-button {
    display: none;
  }

  .slider-position-container--mobile {
    display: flex;
  }
}

.react-date-picker__wrapper {
  border: 0 !important;
}

.Toastify__toast--error {
  background-color: crimson !important;
}

.Toastify__toast--success {
  background-color: var(--main-green) !important;
}

textarea {
  font-family: inherit;
}

* {
  box-sizing: border-box;
}

button {
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &:active,
  &:visited,
  &:focus {
    outline: none;
  }
}

.text-input--warn {
  border-color: rgb(160, 0, 0) !important;
  background-color: rgba(160, 0, 0, 0.1) !important;
  margin-bottom: 7.5px !important;
}

.react-datepicker {
  &-wrapper {
    width: 100%;
  }
  &__current-month {
    color: #74b7bb !important;
  }
  &__navigation--previous {
    border-right-color: #74b7bb !important;
  }
  &__navigation--next {
    border-left-color: #74b7bb !important;
  }
  &-time__header {
    color: #74b7bb !important;
  }
  &-year-header {
    padding-bottom: 5px !important;
    color: #74b7bb !important;
  }
  &__year-text {
    padding-top: 3px !important;
    padding-bottom: 2px !important;
  }
  &__day {
    &-name {
      color: #74b7bb !important;
    }
    &--selected,
    &--today {
      background-color: #74b7bb !important;
    }
  }
}

ul[data-display-name="Recently Used"] {
  display: none !important;
}
