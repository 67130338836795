@media screen and (max-width: 700px) {
  .game-settings {
    padding: 20px 10px;
    &__image {
      width: 300px;
    }

    &__list {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 600px;
    }

    &__label {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &__text {
      margin-bottom: 12px;
    }
  }
}