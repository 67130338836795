.search-course {
  display: flex;
  flex-direction: row;
  padding: 12px 15px;
  cursor: pointer;

  &:hover {
    background-color: rgba(109, 194, 187, 0.2);
  }

  &__image {
    width: 40px;
    height: 40px;
    object-fit: cover;
    margin-right: 15px;
    border-radius: 5px;
  }
}