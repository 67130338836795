@media screen and (max-width: 500px) {
  .login {
    width: 100%;
    max-height: 250vh;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &__header {
      border-radius: 0;
      padding: 25px;
    }

    &__body {
      padding: 20px 20px 10px 20px;
      &--reset {
        transform: translateY(40%);
      }
    }

    &__close-button {
      top: -6px;
      right: 0;
      padding: 18px;
    }

    &__enter-item {
      width: 100%;
      max-width: 300px;
      flex-grow: 1;
    }

    &__label {
      display: flex;
      width: 100%;
      flex-direction: column;
    }

    &__form {
      flex-grow: 1;
      max-width: 300px;
      width: 100%;
    }

    &__input {
      max-width: 300px;
      width: 100%;
      padding: 10px;
      border: 1px solid #898989;
      border-radius: 10px;
      font-size: 16px;
    }

    &__button {
      max-width: 300px;
      width: 100%;
      padding: 14px 0;
      border-radius: 10px;
    }

    &__navigation-link {
      font-size: 16px;
      margin: 0;
      &--big {
        font-size: 16px;
      }
      &--small {
        font-size: 16px;
      }
    }

    &__registration-link,
    &__navigation-wrapper {
      font-size: 16px;
      font-weight: bold;
    }

    &__close-svg-path {
      fill: white;
      &--black {
        fill: black;
      }
    }
  }
}