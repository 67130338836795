.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 40px);
}

.answersList {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 8px;
  overflow-y: auto;
  padding: 10px 20px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 26px;
    background: #c4c4c4;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 26px;
  }
}

.answer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  padding: 6px 0px;
  font-size: 17px;
  font-weight: 500;
  border-bottom: 1px solid #c4c4c47a;
}

.answer__text {
  font-size: inherit;
  font-weight: 500;
}

.answer__icon {
  font-size: 1.5em;
}

.answer_correct {
  color: #60BB52;
}

.answer_incorrect {
  color: #8E2728;
}

.answer strong {
  font-weight: 600;
}

.answerNickname {
  display: inline-block;
  max-width: 225px;
  overflow: hidden;
  text-overflow: ellipsis;
}


.answersGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;
  border-bottom: 2px solid #c4c4c4;
}

.answersGroup__title strong {
  font-weight: 600;
}
