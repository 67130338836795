.courses__medal {
  display: flex;
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
  width: 155px;
  height: 185px;
  background-image: url('../../../../assets/img/medal.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}