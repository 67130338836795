.page-title {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(0,0,0,0.5);

  &__text {
    display: flex;
    max-width: 1200px;
    align-self: center;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    font-weight: 700;
    font-style: normal;
    font-size: 20px;
    margin: 10px 0;
  }
}

@media screen and (max-width: 650px) {
  .page-title {
    border-bottom: 0;

    &__text {
      font-size: 16px;
      margin: 10px 0;
    }
  }
}