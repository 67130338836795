.sex-input {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 15px;
  width: 48%;

  &__container {
    display: flex;
    border: 2px solid var(--main-green);
    font-weight: normal;
    border-radius: 12px;
    margin-top: 5px;
    box-sizing: border-box;
  }

  &__label {
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    padding: 8px 15px;
    color: var(--main-green);
    text-align: center;
    border-radius: 8px;
  }

  &__input:checked + &__label {
    background-color: var(--main-green);
    color: var(--text-white);
  }
}

@media screen and (max-width: 650px) {
  .sex-input {
    width: 100%;
  }
}