$light-blue: #a1d0cc;
$blue: #74b7bb;
$hover-blue: #519599;
$white: #fff;
$dark-text: #25292e;
$grey: #b2b2b2;

.title-wrapper {
  width: 100%;
  display: flex;
  &__number {
    font-size: 27px;
    line-height: 29px;
    color: $white;
    &-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-container {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: $light-blue;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
    }
  }
  &__text {
    font-size: 16px;
    line-height: 18px;
    color: $dark-text;
    text-align: start;
    &-container {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}