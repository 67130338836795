.display {

  &__main-window {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    //border-bottom: 1px solid rgb(150,150,150);
    min-height: 299px;
  }

  .arrow {
    border-color: var(--main-green);
    width: 20px;
    height: 20px;
    border-width: 5px;
  }
}