@media screen and (max-width: 650px) {
  .footer__container {
    display: flex;
    align-items: center;
  }

  .footer__sub-text {
    text-align: center;
  }

  .footer__list {
    justify-content: flex-start;
  }

  .footer__item {
    margin: 0;
    padding: 5px;
    width: 120px;
  }

  .footer__sub-info {
    margin-top: 0;
  }
}