@media screen and (max-width: 650px) {
  .main-slider__image {
    display: none;
  }

  .main-slider__title {
    font-size: 18px;
  }

  .main-slider__text {
    font-size: 14px;
  }


  .main-slider__info {
    margin: 0 auto;
  }
}