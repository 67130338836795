@media screen and (max-width: 900px) {
  .study-slider__card-name {
    font-size: 14px;
  }

  .study-slider__card-title,
  .study-slider__count,
  .study-slider__card-count {
    font-size: 12px;
  }

  .study-slider__card-image {
    width: 150px;
  }
}

@media screen and (max-width: 650px) {
  .study-slider {
    padding: 0 5px;
  }

  .study-slider__card-image {
    width: 140px;
  }

  .study-slider__title {
    text-align: center;
    font-size: 15px;
  }

  .study-slider__header {
    padding: 5px 10px;
    margin-top: 10px;
    margin-bottom: 0;
    justify-content: center;
  }

  .study-slider__card {
    min-height: 104px;
    margin: 0 10px;
  }

  .study-slider__slide {
    min-height: 120px;
  }
}